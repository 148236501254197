import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


import { Label, Menu, Tab, Header, Grid, Button, Segment } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import {
    visitasListAction
    , visitasCodparcSearchAction
    , visitasSearchAction
} from '../../actions/VisitasActions';
import Visitas from '../visitas/Visitas';

import NovoCliente from '../novocliente/NovoCliente';
import Parceiros from '../parceiros/Parceiros';
import Whatsapp from '../whatsapp/Whatasapp';

import {
    novoClienteChangeTabAction

} from '../../actions/NovoClienteActions';


class Main extends Component {

    state = { activeItem: 'Visitas', codigparc: '', ultdtvisitas: '' }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    constructor(props) {
        super(props);

    }

    componentDidMount() {


    }

    handleTabChange = (e, { activeIndex }) => {
        this.props.novoClienteChangeTabAction(activeIndex);
        if (activeIndex === 0 && this.props.existecodparc != 0) {
            alert('temos que carregar a busca')

        }

    }


    render() {

        const { activeItem } = this.props
        const navigateToVisits = (codparc) => {
            this.props.novoClienteChangeTabAction(0)
            // this.props.visitasSearchAction(true)
            // this.props.visitasListAction(codparc).then((item) => {
            //     console.log(item[0].dataultimavisita)
            //     this.setState({ ultdtvisitas: item[0].dataultimavisita })
            //     this.props.visitasSearchAction(false)
            // })

            this.props.visitasCodparcSearchAction(codparc)

        }

        // console.log(this.state.ultdtvisitas)

        const panes = [


            {
                menuItem: { key: 'visitas', icon: 'map marker', content: 'Visitas' },
                render: () => <Tab.Pane><Visitas ultdtvisita={this.state.ultdtvisitas} />
                </Tab.Pane>,
            },
            {
                menuItem: { key: 'novo', icon: 'add user', content: '' },
                render: () => <Tab.Pane><NovoCliente />
                </Tab.Pane>,
            },
            {
                menuItem: { key: 'Parceiros', icon: 'search', content: '' },
                render: () => <Tab.Pane><Parceiros navigateToVisits={navigateToVisits} />
                </Tab.Pane>,
            },
            {
                menuItem: { key: 'Whatasapp', icon: 'whatsapp', content: '' },
                render: () => <Tab.Pane><Whatsapp />
                </Tab.Pane>,
            },




        ]



        return (

            <div>

                <Header as='h3' dividing style={{ marginTop: '15px' }}>Área do Consultor
                    <Header.Subheader> <Icon name='user' />{sessionStorage.getItem("apelidoConsultor")}</Header.Subheader>
                </Header>


                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab
                            panes={panes}
                            activeIndex={this.props.tabindex}
                            onTabChange={this.handleTabChange}

                        />
                    </Grid.Column>
                </Grid>

            </div>
        )
    }
}


const mapStateToProps = state => (
    {
        tabindex: state.NovoClienteReducer.tabindex
        , existecodparc: state.NovoClienteReducer.existecodparc
        , codigparc: state.VisitasReducer.codigparc
    }
);


export default withRouter(connect(mapStateToProps, {
    novoClienteChangeTabAction
    , visitasListAction
    , visitasCodparcSearchAction
    , visitasSearchAction

})(Main));

