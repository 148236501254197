import {
    VISITAS_LIST
    , VISITAS_SAVING
    , VISITAS_JUSTIFIED
    , VISITAS_LOGSCREEN
    , VISITAS_HISTORICO_LIST

    , VISITAS_RAMOATIVIDADE_LIST
    , VISITAS_RAMOATIVIDADE_SELECIONADO_LIST

    , VISITAS_PRODUTOSTRABALHA_LIST
    , VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST

    , VISITAS_PRODUTOSPRETENDE_LIST
    , VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST
    , VISITAS_CHANGE
    , VISITAS_CHANGE_WPP
    , VISITAS_ENTITY_LOAD
    , VISITAS_VENDEDORES_LIST
    , VISITAS_VALIDACAO_ADD
    , VISITAS_VALIDACAO_REMOVE

    , VISITAS_WHATSAPP_LIST
    , VISITAS_WPP_ADD
    , VISITAS_WPP_REMOVE
    , VISITAS_ATT_CODPARC
    , VISITAS_SRCH_CODPARC
} from './ActionsTypes'

import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const visitasHandleChangeAction = (event) => {

    // console.log(event.target.codcontato)
    if (event.target.name == 'nomewpp' || event.target.name == 'emailwpp' || event.target.name == 'wpp') {
        // console.log(event.target.getAttribute('codcontato'))
        return {
            type: VISITAS_CHANGE_WPP
            , field: event.target.name
            , payload: event.target.value.toString().trim()
            , codcontato: parseInt(event.target.getAttribute('codcontato'))
        }
    } else if (event.target.name == 'cargo') {
        return {
            type: VISITAS_CHANGE_WPP
            , field: event.target.name
            , payload: event.target.value.toString().trim()
            , codcontato: parseInt(event.target.codcontato)
        }
    }
    else {
        return {
            type: VISITAS_CHANGE
            , field: event.target.name
            , payload: event.target.value.toString().trim()
        }
    }

}

export const visitasAddValidacao = (id, campo, mensagem) => {
    return {
        type: VISITAS_VALIDACAO_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}




export const visitasRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: VISITAS_VALIDACAO_REMOVE, payload: [] })
        resolve([])
    }
    );





export const visitasListAction = (idparceiro) => (dispatch) =>
    new Promise(function (resolve, reject) {



        dispatch({ type: VISITAS_ENTITY_LOAD, field: "codparc", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "email", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "telefoneprincipal", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "telefonesecundario", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "justificativa", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "codvend", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "interesseadsite", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessedisplay", payload: '' })
        dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessetreinamentos", payload: '' })



        if (idparceiro === '') {
            idparceiro = -1
        }

        dispatch({ type: VISITAS_HISTORICO_LIST, payload: [] })
        dispatch({ type: VISITAS_LIST, payload: [] })
        //dispatch(visitasRamosProdutosAction())
        dispatch(visitasRemoveValidacao())



        Api.getAllVisitas(idparceiro).then(dados => {
            dispatch({ type: VISITAS_LOGSCREEN, payload: [] })
            dispatch({ type: VISITAS_LIST, payload: dados })


            // console.log(dados[0])
            if (dados.length > 0) {
                dispatch({ type: VISITAS_WHATSAPP_LIST, payload: dados[0].whatsapp })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "codparc", payload: dados[0].codigoparceiro })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "email", payload: dados[0].email })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "telefoneprincipal", payload: dados[0].telefoneprincipal })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "telefonesecundario", payload: dados[0].telefonesecundario })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "codvend", payload: dados[0].codigovendedor })

                dispatch({ type: VISITAS_ENTITY_LOAD, field: "dias_de_cadastro", payload: dados[0].dias_de_cadastro })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "dias_sem_comprar", payload: dados[0].dias_sem_comprar })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "dias_sem_cttprog", payload: dados[0].dias_sem_cttprog })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "inadimplente", payload: dados[0].inadimplente })


                dispatch({ type: VISITAS_ENTITY_LOAD, field: "interesseadsite", payload: dados[0].interesseadsite })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessedisplay", payload: dados[0].interessedisplay })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessetreinamentos", payload: dados[0].treinamentos })

                dispatch({ type: VISITAS_ENTITY_LOAD, field: "mostrouamost", payload: dados[0].mostrouamostra })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "mostroub2b", payload: dados[0].mostroub2b })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "classificacaofin", payload: dados[0].classificacaofin })

                dispatch({ type: VISITAS_ENTITY_LOAD, field: "dtclassificacaofin", payload: dados[0].dtclassificacaofin })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "valormina", payload: dados[0].valormina })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "valorminb", payload: dados[0].valorminb })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "valormeses", payload: dados[0].valormeses })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "mesestotal", payload: dados[0].mesestotal })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "vendativo", payload: dados[0].vendativo })
                dispatch({ type: VISITAS_ENTITY_LOAD, field: "isconsultor", payload: dados[0].isconsultor })

            }

            //Ramo de Atividade selecionado------------------------------------------------------------
            let ramoselecionado = []
            if (dados.length > 0) {
                for (let ramo of dados[0].ramoatividade) {
                    ramoselecionado.push(ramo.codtipo.toString())
                }
            }
            //dispatch({ type: VISITAS_RAMOATIVIDADE_SELECIONADO_LIST, payload: ["10605000", "10609000"] })
            dispatch({ type: VISITAS_RAMOATIVIDADE_SELECIONADO_LIST, payload: ramoselecionado })


            let ramoatividadeall = []
            if (dados.length > 0) {
                for (let r of dados[0].ramoatividadeall) {
                    ramoatividadeall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: VISITAS_RAMOATIVIDADE_LIST, payload: ramoatividadeall })
            //----------------------------------------------------------------------------------------







            //Produtos que trabalha selecionado-------------------------------------------------------
            let produtotrabalhaselecionado = []
            if (dados.length > 0) {
                for (let produto of dados[0].produtosquetrabalha) {
                    produtotrabalhaselecionado.push(produto.codtipo.toString())
                }
            }
            dispatch({ type: VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST, payload: produtotrabalhaselecionado })

            let produtosquetrabalhaall = []
            if (dados.length > 0) {
                for (let r of dados[0].produtosquetrabalhaall) {
                    produtosquetrabalhaall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: VISITAS_PRODUTOSTRABALHA_LIST, payload: produtosquetrabalhaall })
            //----------------------------------------------------------------------------------------

            //Produtos que pretente selecionado-------------------------------------------------------
            let produtopretenteselecionado = []
            if (dados.length > 0) {
                for (let produto of dados[0].produtosquepretende) {
                    produtopretenteselecionado.push(produto.codtipo.toString())
                }
            }
            dispatch({ type: VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST, payload: produtopretenteselecionado })

            let produtosquepretendeall = []
            if (dados.length > 0) {
                for (let r of dados[0].produtosquepretendeall) {
                    produtosquepretendeall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: VISITAS_PRODUTOSPRETENDE_LIST, payload: produtosquepretendeall })
            //----------------------------------------------------------------------------------------


            //Vendedores------------------------------------------------------------------------------

            let vendedores = []
            vendedores.push({ key: "0", text: "Sem vendedor", value: "0" })
            if (dados.length > 0) {
                for (let r of dados[0].vendedores) {
                    vendedores.push({ key: r.codvend.toString(), text: r.apelido, value: r.codvend.toString() })
                }
            }
            dispatch({ type: VISITAS_VENDEDORES_LIST, payload: vendedores })
            //dispatch({ type: VISITAS_RAMOATIVIDADE_SELECIONADO_LIST, payload: ["146"] })
            //----------------------------------------------------------------------------------------

            resolve(dados)
            //resolve([])
        }).catch(error => {
            reject(error);
        })



        Api.getAllVisitasHistorico(idparceiro).then(hist => {
            dispatch({ type: VISITAS_HISTORICO_LIST, payload: hist })
        })



    });

export const visitasSaveExtraAction = (dataExtra) => (dispatch) =>
    new Promise(function (resolve, reject) {

        if (dataExtra.dropVendedores != '') {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "codvend", payload: dataExtra.dropVendedores })
        } else {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "codvend", payload: '0' })
        }


        if (dataExtra.dropRamo != '') {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "ramoatividade", payload: dataExtra.dropRamo })
        } else {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "ramoatividade", payload: '' })
        }

        if (dataExtra.dropTrabalha != '') {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "produtosquetrabalha", payload: dataExtra.dropTrabalha })
        } else {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "produtosquetrabalha", payload: '' })
        }

        if (dataExtra.dropPretende != '') {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "produtosquepretende", payload: dataExtra.dropPretende })
        } else {
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "produtosquepretende", payload: '' })
        }


        resolve([])

    });
export const justificativaAction = (item) => (dispatch) => {
    new Promise(function (resolve, reject) {
        // console.log(item)
        if (item == false) {
            dispatch({ type: VISITAS_JUSTIFIED, payload: false })
        } else {
            dispatch({ type: VISITAS_JUSTIFIED, payload: true })
        }

    })
}
export const visitasSaveAction = (formData, VisitaEntity) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: VISITAS_SAVING, payload: true })

        //console.log('visita')
        console.log(VisitaEntity)

        formData.VisitaEntity = VisitaEntity



        Api.saveVisita(formData).then(result => {
            //sleep( 10000 );
            dispatch({ type: VISITAS_LOGSCREEN, payload: result })
            dispatch({ type: VISITAS_SAVING, payload: false })
            dispatch({ type: VISITAS_LIST, payload: [] })
            dispatch({ type: VISITAS_HISTORICO_LIST, payload: [] })

            dispatch({ type: VISITAS_ENTITY_LOAD, field: "interesseadsite", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessedisplay", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "interessetreinamentos", payload: '' })

            dispatch({ type: VISITAS_ENTITY_LOAD, field: "msgvendedor", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "finreavaliacaodecredito", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "finpagardividas", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "comfaltacontato", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "msgpesquisamercado", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "logmaterialdanificado", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "logentregaobrasobeescada", payload: '' })

            dispatch({ type: VISITAS_ENTITY_LOAD, field: "km", payload: 0 })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "conversa", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "justificativa", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "sigiloso", payload: '' })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "naoalteraconsultor", payload: '' })

            dispatch({ type: VISITAS_ENTITY_LOAD, field: "kminicial", payload: 0 })
            dispatch({ type: VISITAS_ENTITY_LOAD, field: "kmfinal", payload: 0 })
        })

        // dispatch({ type: VISITAS_SAVING, payload: false })
    });

export const visitasRamosProdutosClearAction = (formData) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: VISITAS_RAMOATIVIDADE_SELECIONADO_LIST, payload: [] })
        dispatch({ type: VISITAS_RAMOATIVIDADE_LIST, payload: [] })
        dispatch({ type: VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST, payload: [] })
        dispatch({ type: VISITAS_PRODUTOSTRABALHA_LIST, payload: [] })
        dispatch({ type: VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST, payload: [] })
        dispatch({ type: VISITAS_PRODUTOSPRETENDE_LIST, payload: [] })
    });


function sleep(ms) {
    let start = Date.now();
    while (Date.now() < start + ms) { }
}


export const visitasWppAddAction = (item) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(item)
        dispatch({ type: VISITAS_WPP_ADD, payload: item })
        resolve([])

    });

export const visitasWppRemoveAction = (item) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(item)
        dispatch({ type: VISITAS_WPP_REMOVE, payload: item })
        resolve([])

    });


export const visitasCodparcSearchAction = (codparc) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: VISITAS_ATT_CODPARC, payload: codparc })
        resolve([])

    });

export const visitasSearchAction = (loading) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: VISITAS_SRCH_CODPARC, payload: loading })
        resolve([])

    });