import React, { Component } from 'react'
import moment from 'moment';
import 'moment/locale/pt-br'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import { DebounceInput } from 'react-debounce-input';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types'

import {
    visitasListAction

    , visitasSaveAction
    , justificativaAction
    , visitasRamosProdutosClearAction
    , visitasHandleChangeAction
    , visitasSaveExtraAction
    , visitasAddValidacao
    , visitasRemoveValidacao
    , visitasWppAddAction
    , visitasWppRemoveAction
} from '../../actions/VisitasActions';

import {
    novoClienteDocumentoAction
} from '../../actions/NovoClienteActions';


import InputMask from 'react-input-mask';

import { loginValidAction } from '../../actions/LoginActions';
import LogScreen from '../logscreen/LogScreen';
import If from '../../utils/If';

import {
    Label, Segment,
    Message, Checkbox, Form
    , TextArea, Dropdown, Button, Icon, Card, Divider
} from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Visitas extends Component {

    state = {
        startDate: null,
        dtultivisita: null,
        initialSearch: true,
        loading: false,
        saving: false,
        chkFinanceiro: 'N',
        chkFechou: 'N',
        chkFinanceiroMSG: '',
        searchQuery: '',
        dropRamo: '',
        dropTrabalha: '',
        dropPretende: '',
        dropVendedores: '',
        formError: 0,
        formErrorMessage: [],
        emailError: 0,
        telefonePrincipalError: 0,
        telefoneSecundarioError: 0,
        justificativa: '',
        nomewpp: '',
        emailwpp: '',
        cargo: '',
        wpp: '',
        setErronomewpp: false,
        setErroemailwpp: false,
        setErrocargo: false,
        setErrowhatsapp: false,
        semwpp: 'N'
    };


    constructor(props) {
        super(props)
        //this.combo = React.createRef();
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    handleChangeChkIntADSite = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.interesseadsite === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'interesseadsite', 'value': valor.toString() } })
    }

    handleChangeChkIntDisplay = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.interessedisplay === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'interessedisplay', 'value': valor.toString() } })
    }

    handleChangeChkIntTreinamentos = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.interessetreinamentos === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'interessetreinamentos', 'value': valor.toString() } })
    }


    handleChangeChkAmostras = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.mostrouamost === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'mostrouamost', 'value': valor.toString() } })
    }


    handleChangeChkB2B = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.mostroub2b === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'mostroub2b', 'value': valor.toString() } })
    }



    handleChangeChkComContato = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.comfaltacontato === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'comfaltacontato', 'value': valor.toString() } })
    }


    handleChangeChkfinreavaliacaodecredito = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.finreavaliacaodecredito === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'finreavaliacaodecredito', 'value': valor.toString() } })
    }
    handleChangeChkfinpagardividas = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.finpagardividas === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'finpagardividas', 'value': valor.toString() } })
    }
    handleChangeChklogmaterialdanificado = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.logmaterialdanificado === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'logmaterialdanificado', 'value': valor.toString() } })
    }

    handleChangeChklogentregaobrasobeescada = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.logentregaobrasobeescada === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'logentregaobrasobeescada', 'value': valor.toString() } })
    }



    handleChangeChknaoalteraconsultor = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.props.VisitaEntity.naoalteraconsultor === 'S')
            valor = 'N'
        this.props.visitasHandleChangeAction({ target: { 'name': 'naoalteraconsultor', 'value': valor.toString() } })
    }



    handleForm = (event) => {
        // console.log(event.target.getAttribute('codcontato'));
        // console.log("   ###########################")
        this.props.visitasHandleChangeAction(event)
    }

    //DropDowns
    handleVendedores = (e, { value }) => {
        this.setState({ dropVendedores: value.toString() });
        this.props.visitasHandleChangeAction({ target: { 'name': 'codvend', 'value': value.toString() } })
    }

    handleClassificacaoFin = (e, { value }) => {
        this.setState({ dropClassificacaoFin: value.toString() });
        this.props.visitasHandleChangeAction({ target: { 'name': 'classificacaofin', 'value': value.toString() } })
    }

    handleRamoAtividade = (e, { value }) => {
        this.setState({ dropRamo: value.toString() });
    }

    handleProdutosTrabalha = (e, { value }) => {
        this.setState({ dropTrabalha: value.toString() });
    }

    handleProdutosPretende = (e, { value }) => {
        this.setState({ dropPretende: value.toString() });
    }



    handleChangeDate(date) {
        this.setState({ startDate: date });
        //console.log('data no click:'+date)
    }

    handleChange = (e, { value }) => this.setState({ chkTreinamentos: value })



    handleChangeChkFechou = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && this.state.chkFechou === 'S')
            valor = 'N'
        this.setState({ chkFechou: valor })
    }

    handleChangeChkFinanceiro = (e, { value }) => {
        let valor = value

        if (valor === 'S' && this.state.chkFinanceiro === 'S')
            valor = 'N'

        if (valor.trim() === '')
            valor = 'N'

        if (this.state.chkFinanceiroMSG.trim() != '' && valor === 'N')
            valor = 'S'

        if (this.state.chkFinanceiroMSG.trim().length === 1 && valor === 'S') {
            valor = 'S'
            this.setState({ chkFinanceiroMSG: '' })
        }

        this.setState({ chkFinanceiro: valor })
    }


    isEmail = (email = null) => {
        const { VisitaEntity } = this.props;
        if (VisitaEntity.isconsultor == 0) {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email.includes("@esplane")) {
                return false;
            }
            return regex.test(email);
        }
        return true
    }

    handleChangeMsgFinanceiro = (e, { value }) => {
        let valor = value


        if (valor.trim() != '') {
            this.setState({ chkFinanceiro: 'S' })

            this.setState({ chkFinanceiroMSG: value })
        } else {
            this.setState({ chkFinanceiro: 'N' })
        }

    }


    componentDidMount(prevProps) {
        this.props.loginValidAction(this.props.isAuthenticated, this.props.history)
        var date_start = moment(new Date()).utc();
        this.setState({ startDate: isNaN(date_start) ? null : date_start });
    }

    componentDidUpdate(prevProps) {
        const { whatsapp, codigparc, ramoatividadeSelecionado, produtostrabalhaSelecionado } = this.props;
        // Verifica se a propriedade whatsapp mudou desde a última atualização
        if (prevProps.whatsapp !== whatsapp) {
            // Verifica se há contatos sem WhatsApp
            // console.log(whatsapp.filter(contact => contact.cargo === "Sem WhatsApp"))
            if (whatsapp.filter(contact => contact.cargo === "Sem WhatsApp").length > 0) {
                // Atualiza o estado semwpp se houver contatos sem WhatsApp
                this.setState({ semwpp: "S" });
            } else {
                this.setState({ semwpp: "N" });
            }
        }


    }


    componentWillMount() {
        let codigparc = this.props.codigparc;
        let codparc = this.props.existecodparc;
        // alert(`will codigparc: ${codigparc}`);
        //alert('vai montar'+this.props.existecodparc)
        if (this.props.codigparc != 0) {
            this.searchQuery(codigparc)

            this.props.novoClienteDocumentoAction(0, 0);
        } else {
            // this.searchQuery('')

        }

    }

    searchQuery = (query) => {

        // removendo erro de todos campos ao pesquisar


        this.setState({
            setErronomewpp: false,
            setErroemailwpp: false,
            setErrocargo: false,
            setErrowhatsapp: false,
        });
        this.setState({
            nomewpp: '',
            emailwpp: '',
            cargo: '',
            wpp: '',
        });
        this.props.visitasRamosProdutosClearAction()

        this.setState({ dropRamo: "", dropTrabalha: "", dropPretende: "", dropVendedores: "", dropClassificacaoFin: "" })


        this.setState({ loading: true })

        this.setState({ chkFinanceiro: 'N', chkFechou: 'N', chkFinanceiroMSG: '' })


        if (query === '') {
            this.setState({ initialSearch: true })
        }

        this.setState({ searchQuery: query })

        this.props.visitasListAction(query).then(result => {
            if (result.length > 0) {
                this.setState({ loading: false })
                const datePicker = document.getElementsByClassName("react-datepicker__input-container")[0];
                datePicker?.childNodes?.[0].setAttribute("readOnly", true);

                //recuperando valores
                this.setState({ chkTreinamentos: this.props.visitas?.[0]?.treinamentos })

                //03/02/2023 - deixamos de trazer a data da última visita para sempre trazer a data do dia atual.


                this.setState({ dtultivisita: this.props.visitas?.[0]?.dataultimavisita });

                var date_start = moment(new Date()).utc();
                this.setState({ startDate: isNaN(date_start) ? null : date_start });
            }

            if (query != '' && result.length === 0) {
                this.setState({ initialSearch: false })
            }

            this.setState({ loading: false })


            let { ramoatividadeSelecionado
                , produtostrabalhaSelecionado
                , produtospretendeSelecionado } = this.props

            let countSelected = 0

            var strRamo = ''
            for (let i of ramoatividadeSelecionado) {
                if (countSelected > 0) {
                    strRamo = strRamo.concat(',' + i.toString())
                } else {
                    strRamo = strRamo.concat(i.toString())
                }
                countSelected++
            }

            var strTrabalha = ''
            countSelected = 0
            for (let i of produtostrabalhaSelecionado) {
                if (countSelected > 0) {
                    strTrabalha = strTrabalha.concat(',' + i.toString())
                } else {
                    strTrabalha = strTrabalha.concat(i.toString())
                }
                countSelected++
            }

            var strPretende = ''
            countSelected = 0
            for (let i of produtospretendeSelecionado) {
                if (countSelected > 0) {
                    strPretende = strPretende.concat(',' + i.toString())
                } else {
                    strPretende = strPretende.concat(i.toString())
                }
                countSelected++
            }



            var strVendedor = ''
            if (result.length > 0) {
                strVendedor = this.props.visitas[0].codigovendedor
            }

            var strClassfin = ''
            if (result.length > 0) {
                strClassfin = this.props.visitas[0]?.classificacaofin
            }

            this.setState({ dropRamo: strRamo.toString(), dropTrabalha: strTrabalha, dropPretende: strPretende, dropVendedores: strVendedor, dropClassificacaoFin: strClassfin })

            console.log()
        })
    }

    adicionaErro = (campo, mensagem) => {
        let mensagens = this.state.formErrorMessage
        if (mensagens.filter(a => a.item === campo).length === 0) {
            mensagens.push({ item: campo, mensagem: mensagem, status: 'Error' })
            this.setState({ formErrorMessage: mensagens })
        }
    }

    removeErro = (campo) => {
        //console.log('remove:' + campo)
        //this.setState({ formErrorMessage: [] })
        let dados = this.state.formErrorMessage.filter(e => e.item !== campo)

        this.setState({ formErrorMessage: dados })
    }



    validaForm = () =>
        new Promise(function (resolve, reject) {

            resolve([])
        }
        );

    isEmail = (email = null) => {
        const { VisitaEntity } = this.props;
        if (VisitaEntity.isconsultor == 0) {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email !== null) {
                if (email.includes("@esplane")) {
                    return false;
                }
                return regex.test(email) || email.includes('@esplane');
            }
        }
        return true
    }


    salvarVisita = (formData) => {

        if (this.props.isSaving === false) {
            let error = 0;
            //this.setState({ formErrorMessage: [] })
            this.props.visitasRemoveValidacao().then(result => {

                if (this.isEmail(this.props.VisitaEntity.email) === false) {
                    this.props.visitasAddValidacao(1, "E-mail", "É necessário preencher")
                    //this.adicionaErro("E-mail", "É necessário preencher com e-mail válido")
                    this.setState({ emailError: 1 })
                    error = 1
                } else {
                    //this.removeErro("E-mail")
                    this.setState({ emailError: 0 })
                }

                if (this.props.VisitaEntity.telefoneprincipal != null) {
                    if (this.props.VisitaEntity.telefoneprincipal === '' || this.props.VisitaEntity.telefoneprincipal.toString().trim().length < 11) {
                        //this.adicionaErro("Telefone Principal", "É necessário preencher o campo")
                        this.props.visitasAddValidacao(2, "Telefone Principal", "É necessário preencher corretamente")
                        this.setState({ telefonePrincipalError: 1 })
                        error = 1
                    } else {
                        // this.removeErro("Telefone Principal")
                        this.setState({ telefonePrincipalError: 0 })
                    }
                }




                if (this.props.VisitaEntity.telefonesecundario != null) {
                    if (this.props.VisitaEntity.telefonesecundario.toString().trim().length > 0 && this.props.VisitaEntity.telefonesecundario.toString().trim().length < 11) {
                        //this.adicionaErro("Telefone Secundário", "É necessário preencher o campo")
                        this.props.visitasAddValidacao(3, "Telefone Secundário", "Está inválido")
                        this.setState({ telefoneSecundarioError: 1 })
                        error = 1
                    } else {
                        // this.removeErro("Telefone Secundário")
                        this.setState({ telefoneSecundarioError: 0 })
                    }
                }


                if (this.props.VisitaEntity.vendativo === "N") {
                    const codvendinativo = this.props.visitas[0].codigovendedor
                    if (codvendinativo === this.props.VisitaEntity.codvend) {
                        console.log(this.props.VisitaEntity.codvend.toString().trim())
                        this.props.visitasAddValidacao(4, "Vendedor", "Selecione um vendedor ativo")
                        error = 1
                    }

                }
                if (this.props.VisitaEntity.codvend.toString().trim() === "" || this.props.VisitaEntity.codvend.toString().trim() === "0") {

                    this.props.visitasAddValidacao(5, "Vendedor", "Selecione o vendedor que será responsável")
                    error = 1
                }


                if (this.state.startDate === null) {
                    //this.adicionaErro("Telefone Secundário", "É necessário preencher o campo")
                    this.props.visitasAddValidacao(6, "Visita", "Informe a data da visita")
                    error = 1
                }


                if (this.props.tipousuario == 'E') {
                    if (this.props.VisitaEntity.km.toString().trim() === "" || this.props.VisitaEntity.km.toString().trim() === "0") {
                        this.props.visitasAddValidacao(7, "KM", "Informe o km atual do veículo")
                        error = 1
                    }
                }


                if (this.props.VisitaEntity.conversa.toString().trim() === "") {
                    this.props.visitasAddValidacao(8, "Relato", "Informe a ação/conversa realizada com o cliente")
                    error = 1
                }


                if (this.props.tipousuario == 'R') {
                    if (this.props.VisitaEntity.kminicial.toString().trim() === "" || this.props.VisitaEntity.kminicial.toString().trim() === "0") {
                        this.props.visitasAddValidacao(9, "KM Inicial", "Informe o km inicial do veículo")
                        error = 1
                    }
                    if (this.props.VisitaEntity.kmfinal.toString().trim() === "" || this.props.VisitaEntity.kmfinal.toString().trim() === "0") {
                        this.props.visitasAddValidacao(10, "KM Final", "Informe o km final do veículo")
                        error = 1
                    }
                }

                if (this.state.dropRamo == "" || this.state.dropRamo.length < 2) {
                    this.props.visitasAddValidacao(11, "Ramo", "Informe o ramo de atividade do cliente");
                    error = 1;
                }
                this.props.whatsapp.map((item) => {
                    // console.log(item)
                    if (item.cargo == "") {
                        this.props.visitasAddValidacao(12, "WhatsApp", "Preencha o campo Cargo");
                        error = 1
                    }
                    if (item.nomewpp == "") {
                        this.props.visitasAddValidacao(13, "WhatsApp", "Preencha o campo Nome");
                        error = 1
                    }
                    if (item.emailwpp == "" && item.cargo !== "Sem WhatsApp") {
                        this.props.visitasAddValidacao(13, "WhatsApp", "Preencha o campo Email ");
                        error = 1
                    } else {
                        if (!this.isEmail(item.emailwpp) && item.cargo !== "Sem WhatsApp") {
                            this.props.visitasAddValidacao(14, "WhatsApp", "Preencha o campo Email com um email valido ");
                        }
                        error = 1
                    }
                    const whatsappWithoutSpaces = item.wpp ? item.wpp.replace(/\s/g, '') : '';
                    // console.log(item)

                    if (whatsappWithoutSpaces == "" && item.cargo !== "Sem WhatsApp") {
                        this.props.visitasAddValidacao(16, "WhatsApp", "Preencha o campo WhatsApp");
                        error = 1
                    } else {
                        if (whatsappWithoutSpaces !== null && whatsappWithoutSpaces.length < 10 && item.cargo !== "Sem WhatsApp") {
                            this.props.visitasAddValidacao(17, "WhatsApp", 'O número de Whatsapp digitado é inválido');
                            error = 1
                        }

                    }

                })

                if (this.props.isJustificativa == true) {
                    if (this.props.VisitaEntity?.justificativa?.trim() === "") {
                        this.props.visitasAddValidacao(18, "Justificativa", "Informe a justificativa relatada pelo cliente")
                        error = 1
                    }
                }
                if (this.state.nomewpp != '' || this.state.emailwpp != '' || this.state.cargo != '' || this.state.wpp != '') {
                    this.props.visitasAddValidacao(19, "WhatsApp", "Adicione o contato corretamente")
                }
                this.setState({ formError: error })

                if (this.props.validacao.length === 0) {
                    let dados = {
                        codparc: this.props.visitas[0].codigoparceiro,
                        ad_codconsult: this.props.codigoConsultor,
                        ad_dtultvisita: this.state.startDate != null ? moment(this.state.startDate).utc().format("DD/MM/YYYY") : null,
                        ad_treinamentos: this.state.chkTreinamentos
                        , ad_fechou: this.state.chkFechou
                        , ad_financeiro: this.state.chkFinanceiro
                        , ad_financeiromsg: this.state.chkFinanceiroMSG
                        , ad_whatsapp: this.props.whatsapp
                    };

                    // console.log(dados)
                    // console.log(this.props.VisitaEntity)
                    this.props.visitasSaveExtraAction({ dropRamo: this.state.dropRamo, dropTrabalha: this.state.dropTrabalha, dropPretende: this.state.dropPretende, dropVendedores: this.state.dropVendedores }).then(res => {
                        this.props.visitasSaveAction(dados, this.props.VisitaEntity).then(result => {
                            this.setState({ searchQuery: '' })
                            this.setState({ initialSearch: true })
                        });
                    })
                }

            })

        }






    }




    render() {

        let { visitas, isSaving, logScreen, tipousuario, isJustificativa } = this.props

        const { value } = this.state

        let { ramoatividade, ramoatividadeSelecionado
            , produtostrabalha, produtostrabalhaSelecionado
            , produtospretende, produtospretendeSelecionado
            , VisitaEntity, vendedores, whatsapp, isLoadingSrch

        } = this.props


        const cargos = [
            { text: '', value: '' },
            { text: 'WhatsApp único', value: 'WhatsApp único' },
            { text: 'Contato Programado', value: 'Contato Programado' },
            { text: 'Controle de Entregas', value: 'Controle de Entregas' }
        ];
        //-------------joao2
        const checkBoxSemWhatsApp = (e) => {
            this.setState({ semwpp: e }, () => {
                if (this.state.semwpp == "S") {
                    const jsonData = {
                        nomewpp: visitas[0].nomeparceiro,
                        emailwpp: '',
                        cargo: 'Sem WhatsApp',
                        wpp: '',
                        codcontato: whatsapp.length + 150,
                        codparc: visitas[0].codigoparceiro
                    };
                    this.props.visitasWppAddAction(jsonData);
                } else {
                    removeChecked()
                }

            });
        };


        const handleInputSaveWpp = () => {
            const { nomewpp, emailwpp, cargo, wpp, } = this.state;

            //Remove os espaços da maskara do campo do whatsapp


            const whatsappWithoutSpaces = wpp ? wpp.replace(/\s/g, '') : '';


            // Verifica se os campos estão preenchidos
            const setErronomewpp = nomewpp === "";
            const setErroemailwpp = !emailwpp || !this.isEmail(emailwpp);
            const setErrocargo = !cargo;
            const setErrowhatsapp = !wpp || whatsappWithoutSpaces.length < 10;

            // Atualiza os estados de erro
            this.setState({
                setErronomewpp,
                setErroemailwpp,
                setErrocargo,
                setErrowhatsapp
            });

            // Verifica se algum campo estava inválido anteriormente e foi corrigido
            const isAnyFieldCorrected = !setErronomewpp && !setErroemailwpp && !setErrocargo && !setErrowhatsapp;

            if (isAnyFieldCorrected) {
                // Se algum campo inválido foi corrigido, redefine o estado de erro correspondente como falso
                this.setState({
                    setErronomewpp: false,
                    setErroemailwpp: false,
                    setErrocargo: false,
                    setErrowhatsapp: false
                });
            }

            // Verifica se todos os campos são válidos antes de adicionar a visita
            if (!setErronomewpp && !setErroemailwpp && !setErrocargo && !setErrowhatsapp) {
                const jsonData = {
                    nomewpp,
                    emailwpp,
                    cargo,
                    wpp: wpp,
                    codcontato: whatsapp.length + 150,
                    codparc: visitas[0].codigoparceiro
                };
                this.props.visitasWppAddAction(jsonData);
                this.setState({ nomewpp: '' });
                this.setState({ emailwpp: '' });
                this.setState({ cargo: '' });
                this.setState({ wpp: '' });
                // this.setState({ swpp: '' });
            }
        };


        const handleInputRemoveWpp = (codcontato) => {
            // console.log(codcontato)
            this.props.visitasWppRemoveAction(codcontato)

        };

        const removeChecked = () => {
            if (this.state.semwpp == "N") {
                {
                    whatsapp
                        .filter(contact => contact.cargo == "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                        .map((contact, index) => (
                            handleInputRemoveWpp(contact.codcontato)
                        ))
                }
            }
        }


        // jao
        let dias = this.props.VisitaEntity?.dias_sem_comprar;
        let semwppcad = this.props.whatsapp?.length
        if (dias > 90 && dias !== '' || semwppcad == 0 && semwppcad != '' || semwppcad == 0 || dias == null) {
            this.props.justificativaAction(true)
        } else {
            this.props.justificativaAction(false)
        }


        let clienteNovo = false// true = novo/ false = velho
        if (this.props.VisitaEntity?.dias_de_cadastro <= 90) {
            clienteNovo = true
        }

        let color = ''
        let clientedivida = this.props.VisitaEntity?.inadimplente
        let diasSemCtt = parseFloat(this.props.VisitaEntity?.dias_sem_cttprog)
        let diasSemCttsugs = this.props.VisitaEntity?.dias_sem_cttprog
        let justificativalabel = ''
        let bloquear = this.props.visitas?.[0]?.bloquear

        if (dias <= 60 && semwppcad == 0 && dias != null) {
            justificativalabel = 'Digite a justificativa pela qual o cliente não tem WhatsApp cadastrado'

        } else if (dias > 60 && semwppcad == 0 || dias == null && semwppcad == 0) {
            justificativalabel = 'Digite a justificativa pela qual o cliente não está comprando e não tem WhatsApp cadastrado'

        } else if (dias > 60 && semwppcad > 0 || dias == null) {
            justificativalabel = 'Digite a justificativa pela qual o cliente não está comprando'
        }

        // console.log(this.props.VisitaEntity.codigparc !== undefined)


        return (
            <>

                <BrowserView>

                    <div>
                        <div className="ui input icon loading">
                            <DebounceInput
                                minLength={2}
                                debounceTimeout={4000}
                                onChange={event => {
                                    this.searchQuery(event.target.value);
                                    this.props.justificativaAction(false);
                                }} maxLength={9}
                                placeholder="Código do Parceiro..."
                                value={this.state.searchQuery !== '' ? this.state.searchQuery : this.props.codigparc == 0 && this.state.searchQuery !== undefined ? '' : this.props.codigparc}
                            />

                            {this.state.loading || this.props.isLoadingSrch ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}

                        </div>

                        <LogScreen logData={logScreen} />
                        <br />

                        {
                            (visitas.length > 0 && visitas?.[0]?.ativo == "S" ?
                                (


                                    <Segment secondary raised>
                                        <Label as='a' color='red' ribbon>
                                            Parceiro - {visitas[0].codigoparceiro}
                                        </Label>

                                        <Form>
                                            {/*  jao */}
                                            <Form.Group widths='equal' color='gray'>
                                                <div className="field" ><label>Cliente</label>
                                                    <div className="ui fluid input" style={{ marginBottom: '10px' }}>{visitas[0].nomeparceiro}</div>
                                                    <div style={{ backgroundColor: '#fffaf3', padding: '10px', borderRadius: '5px', border: '1px solid #794b02' }}>
                                                        {bloquear == 'S' ? (<div className="ui fluid input" style={{ color: 'red' }}><b>Parceiro Fechado!</b></div>) : ''}
                                                        {clienteNovo === true && dias == null ? (
                                                            <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                        )
                                                            : clienteNovo === true && dias < 90 ? (
                                                                <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                            ) : clienteNovo === false && dias == null ? (
                                                                <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                            ) : ''}
                                                        {dias < 60 && dias != null ? (
                                                            <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                        ) : dias > 60 && dias != null ? (
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                        ) : null}
                                                        {clientedivida > 0 ? (
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Inadimplência de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(clientedivida)}</div>
                                                        ) : ''}
                                                        {diasSemCtt >= 60 ? (
                                                            <>
                                                                <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>{diasSemCtt} dias sem receber Contato Programado</div>
                                                                <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                            </>
                                                        ) : ''}
                                                        {diasSemCttsugs == null && clienteNovo === false || diasSemCttsugs == '' && clienteNovo === false ? (
                                                            <>
                                                                <div className="ui fluid input" style={{ color: 'red' }}><b>Nunca recebeu Contato Programado!</b></div>
                                                                <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                            </>
                                                        ) : ''}
                                                        {semwppcad == 0 ? (
                                                            <>
                                                                <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>Sem WhatsApp cadastrado!</div>
                                                            </>
                                                        ) : ''}

                                                    </div>

                                                </div>


                                                <div className="field"><label>Consultor Externo</label>
                                                    <div className="ui fluid input">{visitas[0].codigoconsultor} - {(visitas[0].codigoconsultor == 0 ? 'Sem consultor' : visitas[0].nomeconsultor)}</div>
                                                    <If test={tipousuario == 'E'}>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Não alterar o consultor'
                                                                name='chknaoalteraconsultor'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.naoalteraconsultor === 'S'}
                                                                onChange={this.handleChangeChknaoalteraconsultor.bind(this)}
                                                            />
                                                        </Form.Field>
                                                    </If>
                                                </div>

                                                <div className="field"><label>Representante</label>
                                                    <div className="ui fluid input">{visitas[0].codigorepresentante} - {(visitas[0].codigorepresentante == 0 ? 'Sem representante' : visitas[0].nomerepresentante)}</div>
                                                </div>


                                                <div className="field"><label>Data da Visita</label>

                                                    <div>

                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeDate}
                                                            onSelect={this.handleChangeDate}
                                                            isClearable={true}
                                                            todayButton={"Hoje"}
                                                        />
                                                    </div>
                                                    <div className="ui fluid input">últ. visita: {(this.state.dtultivisita != null ? moment.utc(this.state.dtultivisita).format("DD/MM/YYYY") : this.props.ultdtvisita != null ? moment.utc(this.props.ultdtvisita).format("DD/MM/YYYY") : "nenhuma")}</div>
                                                </div>

                                                <div className="field"><label>Campo Sigiloso</label>
                                                    <Form.Field control={TextArea} label=''
                                                        id='sigiloso'
                                                        name='sigiloso'
                                                        placeholder='Essa mensagem não estará disponível para o vendedor...'
                                                        onChange={this.handleForm.bind(this)}
                                                        maxLength={2000}
                                                    />
                                                </div>




                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <div className="field">

                                                    {
                                                        (this.state.loading === false ? (
                                                            <Form.Input name="email" fluid label="E-mail" placeholder="E-mail" value={VisitaEntity.email}
                                                                //error={this.state.emailError}
                                                                //onChange={this.props.visitasHandleChangeAction}
                                                                onChange={this.handleForm.bind(this)}
                                                                maxLength={50}
                                                            />


                                                        ) : (<div></div>)
                                                        )}

                                                </div>

                                                <div className="field">
                                                    <label>Telefone Principal:</label>
                                                    <InputMask mask="99 999999999" maskChar=" " name="telefoneprincipal" placeholder="Telefone Principal"
                                                        value={VisitaEntity.telefoneprincipal}
                                                        onChange={this.handleForm.bind(this)}
                                                    />
                                                </div>

                                                <div className="field">
                                                    <label>Telefone Secundário:</label>
                                                    <InputMask
                                                        id="telefonesecundario"
                                                        mask="99 999999999"
                                                        maskChar=" "
                                                        name="telefonesecundario"
                                                        value={VisitaEntity.telefonesecundario || ""} // Se for null, passa uma string vazia
                                                        onChange={this.handleForm.bind(this)}
                                                    />


                                                </div >
                                                <If test={tipousuario == 'E'}>
                                                    <div className="field">
                                                        <label>KM atual do veículo</label>
                                                        <div>
                                                            <InputMask name="km" placeholder="KM atual"
                                                                onChange={this.handleForm.bind(this)}
                                                                mask='999999999'
                                                                maskChar={null}
                                                                style={{ width: "150px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </If>

                                                <If test={tipousuario == 'R'}>
                                                    <div className="field">
                                                        <label>KM inicial</label>
                                                        <div>
                                                            <InputMask name="kminicial" placeholder="KM inicial"
                                                                onChange={this.handleForm.bind(this)}
                                                                mask='999999999'
                                                                maskChar={null}
                                                            // maxLength={10}
                                                            //style={{ width: "100px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <label>KM final</label>
                                                        <div>
                                                            <InputMask name="kmfinal" placeholder="KM final"
                                                                onChange={this.handleForm.bind(this)}
                                                                mask='999999999'
                                                                maskChar={null}
                                                            // maxLength={10}
                                                            //style={{ width: "100px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </If>




                                            </Form.Group>

                                            {/* --------------------------------joao------------------------------------- */}
                                            <Form.Field> <label><b>Contatos WhatsApp</b></label></Form.Field>
                                            <Form.Group widths='equal' style={{ backgroundColor: '#e6e6e6', padding: '10px', borderRadius: '5px' }}>
                                                <Form.Field error={this.state.setErronomewpp}>
                                                    {/* <div></div> */}
                                                    <InputMask
                                                        name="nomewpp"
                                                        // fluid
                                                        placeholder="Nome"
                                                        onChange={(e) => this.setState({ nomewpp: e.target.value })}
                                                        value={this.state.nomewpp}
                                                    />
                                                </Form.Field>

                                                <Form.Field error={this.state.setErrocargo}>
                                                    <Dropdown
                                                        options={cargos}
                                                        selection
                                                        placeholder='Cargo'
                                                        name='cargo'
                                                        fluid
                                                        onChange={(e, { value }) => this.setState({ cargo: value })}
                                                        value={this.state.cargo}
                                                    />
                                                </Form.Field>

                                                <Form.Field error={this.state.setErroemailwpp}>
                                                    <InputMask
                                                        name="emailwpp"
                                                        // fluid
                                                        placeholder="E-mail"
                                                        onChange={(e) => this.setState({ emailwpp: e.target.value })}
                                                        value={this.state.emailwpp}
                                                    />
                                                </Form.Field>

                                                <Form.Field error={this.state.setErrowhatsapp}>
                                                    <PatternFormat icon='hashtag'
                                                        name="wpp"
                                                        // fluid
                                                        customInput={Form.Field}
                                                        control='input'
                                                        format="## ##### ####"
                                                        placeholder='Whatsapp 99 99999 9999'
                                                        onChange={(e) => this.setState({ wpp: e.target.value })}
                                                        value={this.state.wpp}
                                                    //onChange={e => setDocumentNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                                    // labelPosition='right corner'
                                                    />
                                                </Form.Field>
                                                <Button icon positive onClick={() => handleInputSaveWpp()} size='mini' basic>
                                                    <Icon name='plus' />
                                                </Button>
                                            </Form.Group>
                                            <Form.Field>

                                                <Checkbox
                                                    label='Parceiro sem WhatsApp'
                                                    name='semwpp'
                                                    checked={this.state.semwpp === 'S'}
                                                    onChange={(e, data) => checkBoxSemWhatsApp(data.checked ? 'S' : 'N')}
                                                    value={this.state.semwpp}
                                                />

                                            </Form.Field>

                                            {/* --------------------------------------------------------------------- */}
                                            <hr></hr>
                                            {whatsapp
                                                .filter(contact => contact.cargo !== "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                                                .map((contact, index) => (
                                                    <Form.Group widths='equal' key={index}>
                                                        <React.Fragment>
                                                            <Form.Field>
                                                                <InputMask
                                                                    name="nomewpp"
                                                                    // fluid
                                                                    placeholder="Nome"
                                                                    onChange={this.handleForm.bind(this)}
                                                                    // maxLength={50}
                                                                    value={contact.nomewpp}
                                                                    codcontato={contact.codcontato}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Dropdown
                                                                    name="cargo"
                                                                    id="cargo"
                                                                    // defaultValue={contact.cargo}
                                                                    options={cargos}
                                                                    selection
                                                                    placeholder='Cargo'
                                                                    onChange={(e, { value }) => this.handleForm({ target: { name: 'cargo', value, codcontato: contact.codcontato } })}
                                                                    fluid
                                                                    codcontato={contact.codcontato}
                                                                    value={contact.cargo}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <InputMask
                                                                    name="emailwpp"
                                                                    // fluid
                                                                    placeholder="E-mail"
                                                                    onChange={this.handleForm.bind(this)}
                                                                    // maxLength={50}
                                                                    value={contact.emailwpp}
                                                                    codcontato={contact.codcontato}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <PatternFormat
                                                                    icon='hashtag'
                                                                    // iconPosition='left'
                                                                    name="wpp"
                                                                    // fluid
                                                                    customInput={Form.Field}
                                                                    control='input'
                                                                    format="## ##### ####"
                                                                    placeholder='Whatsapp 99 99999 9999'
                                                                    onChange={this.handleForm.bind(this)}
                                                                    value={contact.wpp}
                                                                    codcontato={contact.codcontato}
                                                                />
                                                            </Form.Field>
                                                            <Button icon negative onClick={() => handleInputRemoveWpp(contact.codcontato)}>
                                                                <Icon name='x' />
                                                            </Button>
                                                        </React.Fragment>
                                                    </Form.Group>
                                                ))}



                                            {/* --------------------------------------------------------------------- */}
                                            <Form.Group widths='equal'>
                                                {
                                                    (ramoatividade.length > 0 ? (
                                                        //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                        <div className="field">
                                                            <label>Ramo de atividade:</label>
                                                            <div>
                                                                <Dropdown
                                                                    options={ramoatividade}
                                                                    selection
                                                                    placeholder='Ramo de atividade'
                                                                    name='ramoatividade'
                                                                    multiple
                                                                    fluid
                                                                    defaultValue={ramoatividadeSelecionado}
                                                                    onChange={this.handleRamoAtividade}

                                                                />
                                                            </div>

                                                        </div>
                                                    ) : (<div></div>)
                                                    )}

                                                {
                                                    (produtostrabalha.length > 0 ? (
                                                        //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                        <div className="field">
                                                            <label>Produtos que trabalha:</label>
                                                            <div>
                                                                <Dropdown
                                                                    //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                    options={produtostrabalha}
                                                                    selection
                                                                    //value={pedido.ad_tracknentid}
                                                                    //pedido={pedido}
                                                                    placeholder='Produtos que trabalha'
                                                                    name='produtostrabalha'
                                                                    multiple
                                                                    fluid
                                                                    defaultValue={produtostrabalhaSelecionado}
                                                                    onChange={this.handleProdutosTrabalha}
                                                                //ref={this.combo}
                                                                />
                                                            </div>

                                                        </div>
                                                    ) : (<div></div>)
                                                    )}

                                                {
                                                    (produtospretende.length > 0 ? (
                                                        //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                        <div className="field">
                                                            <label>Produtos que pretende trabalhar:</label>
                                                            <div>
                                                                <Dropdown
                                                                    //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                    options={produtospretende}
                                                                    selection
                                                                    //value={pedido.ad_tracknentid}
                                                                    //pedido={pedido}
                                                                    placeholder='Produtos que pretende trabalhar'
                                                                    name='produtospretende'
                                                                    multiple
                                                                    fluid
                                                                    defaultValue={produtospretendeSelecionado}
                                                                    onChange={this.handleProdutosPretende}
                                                                //ref={this.combo}
                                                                />
                                                            </div>

                                                        </div>
                                                    ) : (<div></div>)
                                                    )}
                                            </Form.Group>


                                            <Form.Group widths='equal'>
                                                <div className="field"><label>Financeiro:</label>
                                                    <div>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Reavaliação de crédito'
                                                                name='chkfinreavaliacaodecredito'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.finreavaliacaodecredito === 'S'}
                                                                onChange={this.handleChangeChkfinreavaliacaodecredito.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Pagar dívidas'
                                                                name='chkfinpagardividas'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.finpagardividas === 'S'}
                                                                onChange={this.handleChangeChkfinpagardividas.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Adquirir ADSite'
                                                                name='chkIntADSite'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.interesseadsite === 'S'}
                                                                onChange={this.handleChangeChkIntADSite.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Parceiro fechou ou não existe mais?'
                                                                name='chkFechou'
                                                                value='S'
                                                                checked={this.state.chkFechou === 'S'}
                                                                onChange={this.handleChangeChkFechou.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Enviar mensagem para o financeiro'
                                                                name='chkFinanceiro'
                                                                value='S'
                                                                checked={this.state.chkFinanceiro === 'S'}
                                                                onChange={this.handleChangeChkFinanceiro.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <div className="field">
                                                            <Form.Field control={TextArea} label=''
                                                                placeholder='Digite a mensagem para o depto. financeiro...'
                                                                onChange={this.handleChangeMsgFinanceiro.bind(this)}
                                                                maxLength={2000}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="field"><label>Comercial:</label>
                                                    <div>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Falta contato/retorno'
                                                                name='chkComContato'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.comfaltacontato === 'S'}
                                                                onChange={this.handleChangeChkComContato.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Interesse em Display'
                                                                name='chkIntDisplay'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.interessedisplay === 'S'}
                                                                onChange={this.handleChangeChkIntDisplay.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Interesse em Treinamentos'
                                                                name='chkIntTreinamentos'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.interessetreinamentos === 'S'}
                                                                onChange={this.handleChangeChkIntTreinamentos.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Mostrou mala de amostras?'
                                                                name='mostrouamost'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.mostrouamost === 'S'}
                                                                onChange={this.handleChangeChkAmostras.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Apresentou o B2B?'
                                                                name='mostrouamost'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.mostroub2b === 'S'}
                                                                onChange={this.handleChangeChkB2B.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <div style={{ color: 'red' }}>

                                                            {`                                                
                                    Classificação atual: ${visitas[0].classificacaofin}
                                    Tipo: ${visitas[0].tipoclassificacaofin} | Data: ${moment(visitas[0].dtclassificacaofin).utc().format("DD/MM/YYYY")}
                                    Upgrade por ${visitas[0].valormeses} meses?
                                    Em ${visitas[0].mesestotal} meses para A: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valormina)} e para B: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valorminb)}, com compras em 3 meses diferentes.
                                `}

                                                            <If test={visitas[0].classificacaofin != 'AA' && visitas[0].classificacaofin != 'D' && visitas[0].classificacaofin != 'A'}>

                                                                <Dropdown
                                                                    options={
                                                                        (visitas[0].classificacaofin == 'C'
                                                                            ? [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                                , { key: 'B', text: 'B', value: 'B' }]
                                                                            : [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                            ])
                                                                    }
                                                                    selection
                                                                    placeholder='Classificação Financeira'
                                                                    name='classfin'
                                                                    fluid
                                                                    defaultValue={visitas[0]?.classificacaofin?.toString()}
                                                                    onChange={this.handleClassificacaoFin}
                                                                />
                                                            </If>


                                                        </div>
                                                        <div className="field">
                                                            <Form.Field control={TextArea} label=''
                                                                id='msgpesquisamercado'
                                                                name='msgpesquisamercado'
                                                                placeholder='Informe concorrente,Produto,Preço...'
                                                                onChange={this.handleForm.bind(this)}
                                                                maxLength={2000}
                                                                rows='5'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <div className="field"> <label>Vendedor:</label>
                                                        <div>
                                                            <Dropdown
                                                                options={vendedores}
                                                                selection
                                                                placeholder='Vendedores'
                                                                name='vendedor'
                                                                fluid
                                                                defaultValue={visitas[0].codigovendedor.toString()}
                                                                onChange={this.handleVendedores}
                                                            />
                                                        </div>
                                                        <div className="field">
                                                            <Form.Field control={TextArea} label=''
                                                                id='msgvendedor'
                                                                name='msgvendedor'
                                                                placeholder='Digite a mensagem para o vendedor...'
                                                                onChange={this.handleForm.bind(this)}
                                                                maxLength={2000}
                                                            />
                                                        </div>
                                                    </div>
                                                    <label>Logística:</label>
                                                    <div>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Teve Material Danificado'
                                                                name='chklogmaterialdanificado'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.logmaterialdanificado === 'S'}
                                                                onChange={this.handleChangeChklogmaterialdanificado.bind(this)}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Checkbox
                                                                label='Não entrega em Obras/Escadas'
                                                                name='chklogentregaobrasobeescada'
                                                                value='S'
                                                                checked={this.props.VisitaEntity.logentregaobrasobeescada === 'S'}
                                                                onChange={this.handleChangeChklogentregaobrasobeescada.bind(this)}
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                </div>

                                                <div className="field">

                                                    <div className="field">
                                                        <Form.Field control={TextArea} label='Relato'
                                                            id='conversa'
                                                            name='conversa'
                                                            placeholder='Digite a ação/conversa realizada com o cliente...'
                                                            onChange={this.handleForm.bind(this)}
                                                            maxLength={4000}
                                                            rows='9'
                                                        />
                                                    </div>

                                                </div>
                                                {this.props.isJustificativa == true ? (
                                                    <div className="field">
                                                        <Form.Field
                                                            control={TextArea}
                                                            label='Justificativa'
                                                            id='justificativa'
                                                            name='justificativa'
                                                            placeholder={justificativalabel}
                                                            onChange={this.handleForm.bind(this)}
                                                            maxLength={4000}
                                                            rows='9'
                                                        />
                                                    </div>
                                                ) : ''}

                                            </Form.Group>





                                            <Form.Group widths='equal'>
                                                <div className="field">
                                                    <div>



                                                    </div>
                                                </div>


                                                <div className="field"><label></label>
                                                    <div className="ui fluid input"></div>
                                                </div>
                                            </Form.Group>



                                            {
                                                (this.props.validacao.length > 0 ? (
                                                    <Message negative>
                                                        {this.props.validacao.map(valida => (
                                                            <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                                        ))}

                                                    </Message>
                                                ) : (
                                                    <div></div>
                                                ))
                                            }

                                            <Form.Button primary
                                                loading={isSaving}
                                                onClick={this.salvarVisita.bind(this)}
                                            >Salvar</Form.Button>









                                        </Form>
                                        <br />

                                        {
                                            //20/12/2018 - histórico cancelado pois foi alterado a proc e precisa ajustar o front-end p exibir o novo formato
                                            //<Historico data={historicodata}/>
                                        }





                                    </Segment>


                                ) :
                                (
                                    <div>

                                        {
                                            (visitas?.[0]?.ativo == "N" ? (
                                                <Message warning>
                                                    <Message.Header>Parceiro Inativo</Message.Header>
                                                    <p>O parceiro <b>{this.state.searchQuery}</b> está inativo no Mitra. Por favor, avise o vendedor ou departamento de cadastro.</p>
                                                </Message>
                                            ) : this.state.initialSearch ? (
                                                <Message info>
                                                    <Message.Header>Parceiro não encontrado</Message.Header>
                                                    <p>Digite acima o código do parceiro que visitou. <br />Após salvar, o parceiro será vinculado automaticamente à você.</p>
                                                </Message>
                                            ) : (
                                                <Message warning>
                                                    <Message.Header>Parceiro não encontrado</Message.Header>
                                                    <p>Não encontramos o código de parceiro <b>{this.state.searchQuery}</b>. Por favor digite um código de parceiro ativo</p>
                                                </Message>
                                            ))
                                        }

                                    </div>
                                ))
                        }

                    </div >
                </BrowserView >
                <MobileView>
                    <div>
                        <div className="ui input icon loading">
                            <DebounceInput
                                minLength={2}
                                debounceTimeout={4000}
                                onChange={event => this.searchQuery(event.target.value)}
                                maxLength={9}
                                placeholder="Código do Parceiro..."
                                value={this.state.searchQuery !== '' ? this.state.searchQuery : this.props.codigparc == 0 && this.state.searchQuery !== undefined ? '' : this.props.codigparc}

                            />

                            {this.state.loading || this.props.isLoadingSrch ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}

                        </div>

                        <LogScreen logData={logScreen} />
                        <br />

                        {
                            (visitas.length > 0 && visitas?.[0]?.ativo == "S" ? (


                                <Segment secondary raised>
                                    <Label as='a' color='red' ribbon>
                                        Parceiro - {visitas[0].codigoparceiro}
                                    </Label>

                                    <Form>
                                        <Form.Group widths='equal' color='gray'>
                                            <div className="field" ><label>Cliente</label>
                                                <div className="ui fluid input" style={{ marginBottom: '10px' }}>{visitas[0].nomeparceiro}</div>
                                                <div style={{ backgroundColor: '#fffaf3', padding: '10px', borderRadius: '5px', border: '1px solid #794b02' }}>
                                                    {bloquear == 'S' ? (<div className="ui fluid input" style={{ color: 'red' }}><b>Parceiro Fechado!</b></div>) : ''}
                                                    {clienteNovo === true && dias == null ? (
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                    ) : clienteNovo === true && dias < 90 ? (
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                    ) : clienteNovo === false && dias == null ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                    ) : ''}
                                                    {dias < 60 && dias != null ? (
                                                        <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                    ) : dias > 60 && dias != null ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                    ) : null}
                                                    {clientedivida > 0 ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Inadimplência de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(clientedivida)}</div>
                                                    ) : ''}
                                                    {diasSemCtt >= 60 ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>{diasSemCtt} dias sem receber Contato Programado</div>
                                                            <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                        </>
                                                    ) : ''}
                                                    {diasSemCttsugs == null && clienteNovo === false || diasSemCttsugs == '' && clienteNovo === false ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red' }}><b>Nunca recebeu Contato Programado!</b></div>
                                                            <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                        </>
                                                    ) : ''}
                                                    {semwppcad == 0 ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>Sem WhatsApp cadastrado!</div>
                                                        </>
                                                    ) : ''}

                                                </div>
                                            </div>


                                            <div className="field"><label>Consultor Externo</label>
                                                <div className="ui fluid input">{visitas[0].codigoconsultor} - {(visitas[0].codigoconsultor == 0 ? 'Sem consultor' : visitas[0].nomeconsultor)}</div>
                                                <If test={tipousuario == 'E'}>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Não alterar o consultor'
                                                            name='chknaoalteraconsultor'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.naoalteraconsultor === 'S'}
                                                            onChange={this.handleChangeChknaoalteraconsultor.bind(this)}
                                                        />
                                                    </Form.Field>
                                                </If>
                                            </div>

                                            <div className="field"><label>Representante</label>
                                                <div className="ui fluid input">{visitas[0].codigorepresentante} - {(visitas[0].codigorepresentante == 0 ? 'Sem representante' : visitas[0].nomerepresentante)}</div>
                                            </div>


                                            <div className="field"><label>Data da Visita</label>

                                                <div>

                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChangeDate}
                                                        onSelect={this.handleChangeDate}
                                                        isClearable={true}
                                                        todayButton={"Hoje"}
                                                    />
                                                </div>
                                                <div className="ui fluid input">últ. visita: {(this.props?.ultdtvisita != null ? moment.utc(this.props.dtultivisita).format("DD/MM/YYYY") : this.state.dtultivisita != null ? moment.utc(this.props.ultdtvisita).format("DD/MM/YYYY") : "nenhuma")}</div>
                                            </div>

                                            <div className="field"><label>Campo Sigiloso</label>
                                                <Form.Field control={TextArea} label=''
                                                    id='sigiloso'
                                                    name='sigiloso'
                                                    placeholder='Essa mensagem não estará disponível para o vendedor...'
                                                    onChange={this.handleForm.bind(this)}
                                                    maxLength={2000}
                                                />
                                            </div>




                                        </Form.Group>

                                        <Form.Group widths='equal'>
                                            <div className="field">

                                                {
                                                    (this.state.loading === false ? (
                                                        <Form.Input name="email" fluid label="E-mail" placeholder="E-mail" value={VisitaEntity.email}
                                                            //error={this.state.emailError}
                                                            //onChange={this.props.visitasHandleChangeAction}
                                                            onChange={this.handleForm.bind(this)}
                                                            maxLength={50}
                                                        />


                                                    ) : (<div></div>)
                                                    )}

                                            </div>

                                            <div className="field">
                                                <label>Telefone Principal:</label>
                                                <InputMask mask="99 999999999" maskChar=" " name="telefoneprincipal" placeholder="Telefone Principal"
                                                    value={VisitaEntity.telefoneprincipal}
                                                    onChange={this.handleForm.bind(this)}
                                                />
                                            </div>

                                            <div className="field">
                                                <label>Telefone Secundário:</label>
                                                <InputMask
                                                    id="telefonesecundario"
                                                    mask="99 999999999"
                                                    maskChar=" "
                                                    name="telefonesecundario"
                                                    value={VisitaEntity.telefonesecundario || ""} // Se for null, passa uma string vazia
                                                    onChange={this.handleForm.bind(this)}
                                                />


                                            </div>


                                            <div className="field"> <label>Vendedor:</label>
                                                <div>
                                                    <Dropdown
                                                        options={vendedores}
                                                        selection
                                                        placeholder='Vendedores'
                                                        name='vendedor'
                                                        fluid
                                                        defaultValue={visitas[0].codigovendedor.toString()}
                                                        onChange={this.handleVendedores}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <Form.Field control={TextArea} label=''
                                                        id='msgvendedor'
                                                        name='msgvendedor'
                                                        placeholder='Digite a mensagem para o vendedor...'
                                                        onChange={this.handleForm.bind(this)}
                                                        maxLength={2000}
                                                    />
                                                </div>
                                            </div>

                                            <If test={tipousuario == 'R'}>
                                                <div className="field">
                                                    <label>KM inicial</label>
                                                    <div>
                                                        <InputMask name="kminicial" placeholder="KM inicial"
                                                            onChange={this.handleForm.bind(this)}
                                                            mask='999999999'
                                                            maskChar={null}
                                                        // maxLength={10}
                                                        //style={{ width: "100px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>KM final</label>
                                                    <div>
                                                        <InputMask name="kmfinal" placeholder="KM final"
                                                            onChange={this.handleForm.bind(this)}
                                                            mask='999999999'
                                                            maskChar={null}
                                                        // maxLength={10}
                                                        //style={{ width: "100px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </If>




                                        </Form.Group>

                                        {/* --------------------------------joao------------------------------------- */}
                                        <Form.Field> <label><b>Contatos WhatsApp</b></label></Form.Field>
                                        <Form.Group widths='equal' style={{ backgroundColor: '#e6e6e6', padding: '10px', borderRadius: '5px' }}>
                                            <Form.Field error={this.state.setErronomewpp}>
                                                {/* <div></div> */}
                                                <InputMask
                                                    name="nomewpp"
                                                    // fluid
                                                    placeholder="Nome"
                                                    onChange={(e) => this.setState({ nomewpp: e.target.value })}
                                                    value={this.state.nomewpp}
                                                />
                                            </Form.Field>

                                            <Form.Field error={this.state.setErrocargo}>
                                                <Dropdown
                                                    options={cargos}
                                                    selection
                                                    placeholder='Cargo'
                                                    name='cargo'
                                                    fluid
                                                    onChange={(e, { value }) => this.setState({ cargo: value })}
                                                    value={this.state.cargo}
                                                />
                                            </Form.Field>

                                            <Form.Field error={this.state.setErroemailwpp}>
                                                <InputMask
                                                    name="emailwpp"
                                                    // fluid
                                                    placeholder="E-mail"
                                                    onChange={(e) => this.setState({ emailwpp: e.target.value })}
                                                    // maxLength={50}
                                                    value={this.state.emailwpp}
                                                />
                                            </Form.Field>

                                            <Form.Field error={this.state.setErrowhatsapp}>
                                                <PatternFormat icon='hashtag'
                                                    name="wpp"
                                                    // fluid
                                                    customInput={Form.Field}
                                                    control='input'
                                                    format="## ##### ####"
                                                    placeholder='Whatsapp 99 99999 9999'
                                                    onChange={(e) => this.setState({ wpp: e.target.value })}
                                                    value={this.state.wpp}
                                                //onChange={e => setDocumentNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                                // labelPosition='right corner'
                                                />

                                            </Form.Field>
                                            <Button icon positive fluid onClick={() => handleInputSaveWpp()} size='mini' basic>
                                                Adicionar Contato
                                            </Button>
                                            <hr></hr>
                                            <Form.Field>

                                                <Checkbox
                                                    label='Parceiro sem WhatsApp'
                                                    name='semwpp'
                                                    checked={this.state.semwpp === 'S'}
                                                    onChange={(e, data) => checkBoxSemWhatsApp(data.checked ? 'S' : 'N')}
                                                    value={this.state.semwpp}
                                                />

                                            </Form.Field>

                                        </Form.Group>

                                        {/* --------------------------------------------------------------------- */}
                                        <hr key='space' />

                                        <div>

                                            <Card.Group>

                                                {whatsapp
                                                    .filter(contact => contact.cargo !== "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                                                    .map((contact, index) => (
                                                        <Card fluid key={index}>
                                                            <Card.Content>
                                                                <Card.Description>

                                                                    <Form.Group widths='equal'>
                                                                        <Form.Field>
                                                                            <InputMask
                                                                                name="nomewpp"
                                                                                // fluid
                                                                                placeholder="Nome"
                                                                                onChange={this.handleForm.bind(this)}
                                                                                // maxLength={50}
                                                                                value={contact.nomewpp}
                                                                                codcontato={contact.codcontato}
                                                                            />
                                                                        </Form.Field>
                                                                        <Form.Field>
                                                                            <Dropdown
                                                                                name="cargo"
                                                                                id="cargo"
                                                                                // defaultValue={contact.cargo}
                                                                                options={cargos}
                                                                                selection
                                                                                placeholder='Cargo'
                                                                                onChange={(e, { value }) => this.handleForm({ target: { name: 'cargo', value, codcontato: contact.codcontato } })}
                                                                                fluid
                                                                                codcontato={contact.codcontato}
                                                                                value={contact.cargo}
                                                                            />
                                                                        </Form.Field>

                                                                    </Form.Group>
                                                                    <Form.Group widths='equal'>
                                                                        <Form.Field>
                                                                            <InputMask
                                                                                name="emailwpp"
                                                                                // fluid
                                                                                placeholder="E-mail"
                                                                                onChange={this.handleForm.bind(this)}
                                                                                // maxLength={50}
                                                                                value={contact.emailwpp}
                                                                                codcontato={contact.codcontato}
                                                                            />
                                                                        </Form.Field>

                                                                        <Form.Field>
                                                                            <PatternFormat icon='hashtag'
                                                                                name="wpp"
                                                                                // fluid
                                                                                customInput={Form.Field}
                                                                                control='input'
                                                                                format="## ##### ####"
                                                                                placeholder='Whatsapp 99 99999 9999'
                                                                                onChange={this.handleForm.bind(this)}
                                                                                value={contact.wpp}
                                                                                codcontato={contact.codcontato}

                                                                            />
                                                                        </Form.Field>

                                                                    </Form.Group>


                                                                </Card.Description>
                                                            </Card.Content>
                                                            <Card.Content>

                                                                <div>
                                                                    <Button basic negative fluid onClick={() => handleInputRemoveWpp(contact.codcontato)}>Excluir Contato</Button>
                                                                </div>
                                                            </Card.Content>
                                                        </Card>


                                                    ))}
                                            </Card.Group>
                                        </div>





                                        {/* --------------------------------------------------------------------- */}
                                        <Form.Group widths='equal'>
                                            {
                                                (ramoatividade.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Ramo de atividade:</label>
                                                        <div>
                                                            <Dropdown
                                                                options={ramoatividade}
                                                                selection
                                                                placeholder='Ramo de atividade'
                                                                name='ramoatividade'
                                                                multiple
                                                                fluid
                                                                defaultValue={ramoatividadeSelecionado}
                                                                onChange={this.handleRamoAtividade}

                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}

                                            {
                                                (produtostrabalha.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Produtos que trabalha:</label>
                                                        <div>
                                                            <Dropdown
                                                                //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                options={produtostrabalha}
                                                                selection
                                                                //value={pedido.ad_tracknentid}
                                                                //pedido={pedido}
                                                                placeholder='Produtos que trabalha'
                                                                name='produtostrabalha'
                                                                multiple
                                                                fluid
                                                                defaultValue={produtostrabalhaSelecionado}
                                                                onChange={this.handleProdutosTrabalha}
                                                            //ref={this.combo}
                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}

                                            {
                                                (produtospretende.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Produtos que pretende trabalhar:</label>
                                                        <div>
                                                            <Dropdown
                                                                //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                options={produtospretende}
                                                                selection
                                                                //value={pedido.ad_tracknentid}
                                                                //pedido={pedido}
                                                                placeholder='Produtos que pretende trabalhar'
                                                                name='produtospretende'
                                                                multiple
                                                                fluid
                                                                defaultValue={produtospretendeSelecionado}
                                                                onChange={this.handleProdutosPretende}
                                                            //ref={this.combo}
                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}
                                        </Form.Group>


                                        <Form.Group widths='equal'>
                                            <div className="field"><label>Financeiro:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Reavaliação de crédito'
                                                            name='chkfinreavaliacaodecredito'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.finreavaliacaodecredito === 'S'}
                                                            onChange={this.handleChangeChkfinreavaliacaodecredito.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Pagar dívidas'
                                                            name='chkfinpagardividas'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.finpagardividas === 'S'}
                                                            onChange={this.handleChangeChkfinpagardividas.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Adquirir ADSite'
                                                            name='chkIntADSite'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.interesseadsite === 'S'}
                                                            onChange={this.handleChangeChkIntADSite.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Parceiro fechou ou não existe mais?'
                                                            name='chkFechou'
                                                            value='S'
                                                            checked={this.state.chkFechou === 'S'}
                                                            onChange={this.handleChangeChkFechou.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Enviar mensagem para o financeiro'
                                                            name='chkFinanceiro'
                                                            value='S'
                                                            checked={this.state.chkFinanceiro === 'S'}
                                                            onChange={this.handleChangeChkFinanceiro.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <div className="field">
                                                        <Form.Field control={TextArea} label=''
                                                            placeholder='Digite a mensagem para o depto. financeiro...'
                                                            onChange={this.handleChangeMsgFinanceiro.bind(this)}
                                                            maxLength={2000}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field"><label>Comercial:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Falta contato/retorno'
                                                            name='chkComContato'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.comfaltacontato === 'S'}
                                                            onChange={this.handleChangeChkComContato.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Interesse em Display'
                                                            name='chkIntDisplay'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.interessedisplay === 'S'}
                                                            onChange={this.handleChangeChkIntDisplay.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Interesse em Treinamentos'
                                                            name='chkIntTreinamentos'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.interessetreinamentos === 'S'}
                                                            onChange={this.handleChangeChkIntTreinamentos.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Mostrou mala de amostras?'
                                                            name='mostrouamost'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.mostrouamost === 'S'}
                                                            onChange={this.handleChangeChkAmostras.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Apresentou o B2B?'
                                                            name='mostrouamost'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.mostroub2b === 'S'}
                                                            onChange={this.handleChangeChkB2B.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <div style={{ color: 'red' }}>

                                                        {`                                                
                Classificação atual: ${visitas[0].classificacaofin}
                Tipo: ${visitas[0].tipoclassificacaofin} | Data: ${moment(visitas[0].dtclassificacaofin).utc().format("DD/MM/YYYY")}
                Upgrade por ${visitas[0].valormeses} meses?
                Em ${visitas[0].mesestotal} meses para A: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valormina)} e para B: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valorminb)}, com compras em 3 meses diferentes.
            `}

                                                        <If test={visitas[0].classificacaofin != 'AA' && visitas[0].classificacaofin != 'D' && visitas[0].classificacaofin != 'A'}>

                                                            <Dropdown
                                                                options={
                                                                    (visitas[0].classificacaofin == 'C'
                                                                        ? [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                            , { key: 'B', text: 'B', value: 'B' }]
                                                                        : [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                        ])
                                                                }
                                                                selection
                                                                placeholder='Classificação Financeira'
                                                                name='classfin'
                                                                fluid
                                                                defaultValue={visitas[0]?.classificacaofin?.toString()}
                                                                onChange={this.handleClassificacaoFin}
                                                            />
                                                        </If>


                                                    </div>
                                                    <div className="field">
                                                        <Form.Field control={TextArea} label=''
                                                            id='msgpesquisamercado'
                                                            name='msgpesquisamercado'
                                                            placeholder='Informe concorrente,Produto,Preço...'
                                                            onChange={this.handleForm.bind(this)}
                                                            maxLength={2000}
                                                            rows='5'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field">

                                                <If test={tipousuario == 'E'}>
                                                    <div className="field">

                                                        <label>KM atual do veículo</label>
                                                        <div>
                                                            <InputMask name="km" placeholder="KM atual"

                                                                onChange={this.handleForm.bind(this)}
                                                                //value={VisitaEntity.km}
                                                                mask='999999999'
                                                                maskChar={null}
                                                            // maxLength={10}
                                                            // style={{ width: "150px" }}
                                                            />



                                                        </div>
                                                    </div>
                                                </If>

                                                <label>Logística:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Teve Material Danificado'
                                                            name='chklogmaterialdanificado'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.logmaterialdanificado === 'S'}
                                                            onChange={this.handleChangeChklogmaterialdanificado.bind(this)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Não entrega em Obras/Escadas'
                                                            name='chklogentregaobrasobeescada'
                                                            value='S'
                                                            checked={this.props.VisitaEntity.logentregaobrasobeescada === 'S'}
                                                            onChange={this.handleChangeChklogentregaobrasobeescada.bind(this)}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>

                                            <div className="field">

                                                <div className="field">
                                                    <Form.Field control={TextArea} label='Relato'
                                                        id='conversa'
                                                        name='conversa'
                                                        placeholder='Digite a ação/conversa realizada com o cliente...'
                                                        onChange={this.handleForm.bind(this)}
                                                        maxLength={4000}
                                                        rows='9'
                                                    />
                                                </div>
                                                {isJustificativa === true ? (
                                                    <div className="field">
                                                        <Form.Field
                                                            control={TextArea}
                                                            label='Justificativa'
                                                            id='justificativa'
                                                            name='justificativa'
                                                            placeholder={justificativalabel}
                                                            onChange={this.handleForm.bind(this)}
                                                            maxLength={4000}
                                                            rows='9'
                                                        />
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </Form.Group>





                                        <Form.Group widths='equal'>
                                            <div className="field">
                                                <div>



                                                </div>
                                            </div>


                                            <div className="field"><label></label>
                                                <div className="ui fluid input"></div>
                                            </div>
                                        </Form.Group>



                                        {
                                            (this.props.validacao.length > 0 ? (
                                                <Message negative>
                                                    {this.props.validacao.map(valida => (
                                                        <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                                    ))}

                                                </Message>
                                            ) : (
                                                <div></div>
                                            ))
                                        }

                                        <Form.Button primary
                                            loading={isSaving}
                                            onClick={this.salvarVisita.bind(this)}
                                        >Salvar</Form.Button>









                                    </Form>
                                    <br />

                                    {
                                        //20/12/2018 - histórico cancelado pois foi alterado a proc e precisa ajustar o front-end p exibir o novo formato
                                        //<Historico data={historicodata}/>
                                    }





                                </Segment>


                            ) :
                                (
                                    <div>

                                        {
                                            (visitas?.[0]?.ativo == "N" ? (
                                                <Message warning>
                                                    <Message.Header>Parceiro Inativo</Message.Header>
                                                    <p>O parceiro <b>{this.state.searchQuery}</b> está inativo no Mitra. Por favor, avise o vendedor ou departamento de cadastro.</p>
                                                </Message>
                                            ) : this.state.initialSearch ? (
                                                <Message info>
                                                    <Message.Header>Parceiro não encontrado</Message.Header>
                                                    <p>Digite acima o código do parceiro que visitou. <br />Após salvar, o parceiro será vinculado automaticamente à você.</p>
                                                </Message>
                                            ) : (
                                                <Message warning>
                                                    <Message.Header>Parceiro não encontrado</Message.Header>
                                                    <p>Não encontramos o código de parceiro <b>{this.state.searchQuery}</b>. Por favor digite um código de parceiro ativo</p>
                                                </Message>
                                            ))
                                        }

                                    </div>
                                ))
                        }

                    </div>
                </MobileView >

            </>
        )
    }
}


const mapStateToProps = state => (
    {
        visitas: state.VisitasReducer.visitas,
        isSaving: state.VisitasReducer.isSaving,
        isJustificativa: state.VisitasReducer.isJustificativa,
        logScreen: state.VisitasReducer.logScreen,
        codigoConsultor: state.LoginReducer.codigoConsultor,
        isAuthenticated: state.LoginReducer.isAuthenticated,
        tipousuario: state.LoginReducer.tipousuario,
        historicodata: state.VisitasReducer.historico,
        ramoatividade: state.VisitasReducer.ramoatividade,
        ramoatividadeSelecionado: state.VisitasReducer.ramoatividadeSelecionado,
        produtostrabalha: state.VisitasReducer.produtostrabalha,
        produtostrabalhaSelecionado: state.VisitasReducer.produtostrabalhaSelecionado,
        produtospretende: state.VisitasReducer.produtospretende,
        produtospretendeSelecionado: state.VisitasReducer.produtospretendeSelecionado,
        VisitaEntity: state.VisitasReducer.VisitaEntity,
        vendedores: state.VisitasReducer.vendedores,
        validacao: state.VisitasReducer.validacao,
        existecodparc: state.NovoClienteReducer.existecodparc,
        whatsapp: state.VisitasReducer.whatsapp,
        codigparc: state.VisitasReducer.codigparc,
        isLoadingSrch: state.VisitasReducer.isLoadingSrch

    }
);
Visitas.defaultProps = {
    ultdtvisita: ''
};
Visitas.propTypes = {
    ultdtvisita: PropTypes.string.isRequired
}

export default withRouter(connect(mapStateToProps, {
    visitasListAction
    , visitasSaveAction
    , justificativaAction
    , loginValidAction
    , visitasRamosProdutosClearAction
    , visitasHandleChangeAction
    , visitasSaveExtraAction
    , visitasAddValidacao
    , visitasRemoveValidacao
    , novoClienteDocumentoAction
    , visitasWppAddAction
    , visitasWppRemoveAction
}
)(Visitas));






