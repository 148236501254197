import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import VisitasReducer from './VisitasReducer';
import NovoClienteReducer from './NovoClienteReducer'
import ParceirosReducer from './ParceirosReducer'
import WhatsappReducer from './WhatsappReducer';


export default combineReducers({
    LoginReducer
    , VisitasReducer
    , NovoClienteReducer
    , ParceirosReducer
    , WhatsappReducer
})
