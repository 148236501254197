import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import { PatternFormat } from 'react-number-format';
import {
    whatsappListaContatosAction,
    whatsappCriaAction,
    whatsappContatoChangeAction,
    whatsappContatoAtualizaAction,
    whatsappContatoExcluiAction
    , whatsappContatoChangeFieldAction
} from '../../actions/WhatsappActions';

import {
    Icon, Button, Input, Message, Card, Form, Divider, Header, HeaderContent, HeaderSubheader,
    ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Modal,
    Dropdown,
    Label,
} from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';

function Whatsapp(props) {

    //actions
    const { whatsappListaContatosAction,
        whatsappCriaAction,
        whatsappContatoChangeAction,
        whatsappContatoAtualizaAction,
        whatsappContatoExcluiAction,
        whatsappContatoChangeFieldAction
    } = props;

    //props reducer
    const { contatos, mensagem, status, codparc } = props;

    const [errors, setErrors] = useState([]);
    const [errorSaving, setErrorSaving] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [searchLoading, setSearchLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const [icon, setIcon] = useState('search');
    const [open, setOpen] = useState(false)
    const [excluirOpen, setExcluirOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [salvarOpen, setSalvarOpen] = useState(false)
    const [saveCardLoading, setSaveCardLoading] = useState(false);
    const [partnerExists, setPartnerExists] = useState(true);
    const [contactsExist, setContactsExist] = useState(true);
    const [lerDados, setLerDados] = useState(false);
    const [globalMessage, setGlobalMessage] = useState('');
    const [selectedCardData, setSelectedCardData] = useState({
        nomecontato: '',
        cargo: '',
        email: '',
        whatsapp: ''
    });
    const [selectedSaveCardData, setSelectedSaveCardData] = useState({
        nomecontato: '',
        cargo: '',
        email: '',
        whatsapp: '',
        codcontato: ''
    });
    const [modalData, setModalData] = useState({
        nomecontato: '',
        cargo: '',
        email: '',
        whatsapp: ''
    });


    const handleSearch = (cdparc) => {
        setIcon('');
        setSearchLoading(true);
        setOpen(false)
        //const searchValueNumber = parseFloat(searchValue);
        const jsonValue = {
            "codparc": parseFloat(cdparc)
        }
        setTimeout(() => {
            setOpen(false); // Fecha o Popup
        }, 100);
        whatsappListaContatosAction(jsonValue).then((d) => {
            setIcon('search');
            setSearchLoading(false)
            setLerDados(true);
            // setOpen(false)
            setTimeout(() => {
                setOpen(false); // Fecha o Popup
            }, 100);

        });

    };
    console.log(open)
    const onOpenPopup = () => {
        setOpen(true);
    }
    const onOpenPopupExcluir = () => {
        setExcluirOpen(true);
    }
    const onOpenPopupSalvar = () => {
        setSalvarOpen(true);
    }
    const handleInputChange = (fieldName, value) => {
        setModalData({
            ...modalData,
            [fieldName]: value
        });
    };

    const isEmail = (email = null) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    const handleSave = async () => {
        const { nomecontato, cargo, email, whatsapp } = modalData;
        const newErrors = [];
        const whatsappWithoutSpaces = modalData.whatsapp.replace(/\s/g, '');
        if (!nomecontato.trim()) {
            newErrors.push('O campo Nome do Contato é obrigatório');
        }
        if (!cargo.trim()) {
            newErrors.push('O campo Cargo é obrigatório');
        }

        if (email == '') {
            newErrors.push('O campo E-mail é obrigatório');
        } else {
            if (!isEmail(email)) {
                newErrors.push('O E-mail digitado é inválido');
            }
        }
        if (!whatsapp.trim()) {
            newErrors.push('O campo Whatsapp é obrigatório')
        } else {
            if (whatsappWithoutSpaces.length < 10) {
                newErrors.push('O número de Whatsapp digitado é inválido');
            }
        }
        if (newErrors.length === 0) {
            // console.log(searchValue, modalData) // consoleque exibe usuario criado com sucesso
            setSaveLoading(true)
            setErrors([])
            const jsonValue = {
                "codparc": parseFloat(codparc),
                "nomeparc": modalData.nomecontato,
                "cargo": modalData.cargo,
                "email": modalData.email,
                "whatsapp": modalData.whatsapp
            }
            const jsonValueCodParc = {
                "codparc": parseFloat(codparc)
            }

            await whatsappCriaAction(jsonValue).then((d) => {
                setSuccessMessage('Usuario criado com Sucesso!');
                setTimeout(() => {
                    setSuccessMessage('');  //limpa mensagem de sucesso
                    setOpen(false); // Fecha o Popup
                    setSaveLoading(false)
                }, 1000);

                setModalData({
                    nomecontato: '',
                    cargo: '',
                    email: '',
                    whatsapp: ''
                });
                whatsappListaContatosAction(jsonValueCodParc)

            }).catch((err) => {
                console.log(err)
            });
        }
        else {
            setErrors(newErrors);
        }
    };

    const handleDelete = async () => {
        setDeleteLoading(true);
        const jsonValue = {
            "codparc": parseFloat(codparc),
            "codcontato": selectedCardData.codcontato,
        }
        const jsonValueCodParc = {
            "codparc": parseFloat(codparc)
        }
        await whatsappContatoExcluiAction(jsonValue).then((d) => {
            setTimeout(() => {
                setDeleteLoading(false);
                setExcluirOpen(false);
            }, 1000);
            setSelectedCardData({
                nomecontato: '',
                cargo: '',
                email: '',
                whatsapp: ''
            });
            whatsappListaContatosAction(jsonValueCodParc)
        }).catch((err) => {
            console.log(err)
        });
    };

    const handleSaveCard = () => {
        setSaveCardLoading(true);
        const jsonValue = {
            "codparc": parseFloat(codparc),
            "nomeparc": selectedSaveCardData.nomecontato,
            "cargo": selectedSaveCardData.cargo,
            "email": selectedSaveCardData.email,
            "whatsapp": selectedSaveCardData.whatsapp,
            "codcontato": selectedSaveCardData.codcontato
        }
        const jsonValueCodParc = {
            "codparc": parseFloat(codparc)
        }
        whatsappContatoAtualizaAction(jsonValue).then(d => {

            setTimeout(() => {
                setSaveCardLoading(false);
                setSalvarOpen(false); // Fecha o Popup
            }, 1000);
            setSelectedSaveCardData({
                nomecontato: '',
                cargo: '',
                email: '',
                whatsapp: '',
                codcontato: ''
            });
            whatsappListaContatosAction(jsonValueCodParc)
        }).catch((err) => {
            console.log(err)
        });
    }

    const options = [
        { key: 1, text: 'Contato Programado', value: 'Contato Programado' },
        { key: 2, text: 'Controle de Entregas', value: 'Controle de Entregas' },
        { key: 3, text: 'WhatsApp único', value: 'WhatsApp unico' },
    ]

    const validaErro = (item) => {
        let listError = [];

        const whatsappWithoutSpaces = item.ad_whatsappcp.replace(/\s/g, '');
        if (!item.nomecontato.trim()) {
            listError.push({ codcontato: item.codcontato, campo: 'nomecontato' })
        }
        if (item.email == '') {
            listError.push({ codcontato: item.codcontato, campo: 'email' })
        } else {
            if (!isEmail(item.email)) {
                listError.push({ codcontato: item.codcontato, campo: 'email' })
            }
        }
        if (!item.ad_whatsappcp.trim()) {
            listError.push({ codcontato: item.codcontato, campo: 'ad_whatsappcp' })
        } else {
            if (whatsappWithoutSpaces.length < 10) {
                listError.push({ codcontato: item.codcontato, campo: 'ad_whatsappcp' })
            }
        }
        setErrorSaving(listError);
        if (listError.length == 0) {
            onOpenPopupSalvar()
        }

    }


    const handleCargo = (e, { value, name, dados }) => {
        whatsappContatoChangeAction({ target: { 'name': name, 'value': value } }, dados);
    }

    const error = true
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}

            >
                <ModalHeader>Adicionar Novo Contato</ModalHeader>
                {successMessage && (
                    <Message success header="Sucesso!" content={successMessage} />
                )}
                {errors.length > 0 && (
                    <Message
                        error
                        header="Houve alguns erros com o seu envio"
                        list={errors} // Exibe os erros aqui
                    />
                )}
                <ModalContent image scrolling>
                    <ModalDescription>
                        <Card.Group>
                            <Card fluid>
                                <Divider hidden />
                                <Card.Content>
                                    <Card.Description>
                                        <Form size={'mini'} key={'mini'}>
                                            <Form.Group widths='equal'>
                                                <Form.Field
                                                    name='nomecontato'
                                                    control='input'
                                                    maxLength={40}
                                                    placeholder='Nome do Contato'
                                                    onChange={(e) => handleInputChange('nomecontato', e.target.value)}
                                                />
                                                <Form.Field>
                                                    <Dropdown
                                                        name='cargo'
                                                        options={options}
                                                        placeholder='Cargo'
                                                        selection
                                                        onChange={(e, data) => handleInputChange('cargo', data.value)}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group widths='equal'>
                                                <Form.Field
                                                    name='email'
                                                    control='input'
                                                    maxLength={80}
                                                    placeholder='Email'
                                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                                />
                                                <PatternFormat icon='hashtag' iconPosition='left'
                                                    customInput={Form.Field}
                                                    control='input'
                                                    format="## ##### ####"
                                                    placeholder='Whatsapp 99 99999 9999'
                                                    onChange={(e) => handleInputChange('whatsapp', e.target.value)}
                                                //onChange={e => setDocumentNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                                // labelPosition='right corner'
                                                />
                                                {/* <Form.Field
                                                    control='input'
                                                    placeholder='WhatsApp 99 99999-9999'
                                                    onChange={(e) => handleInputChange('whatsapp', e.target.value)}
                                                /> */}
                                            </Form.Group>
                                        </Form>
                                        <Divider hidden />
                                        <Divider hidden />
                                        <Divider hidden />
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </ModalDescription>
                </ModalContent>
                <ModalActions>
                    <Button onClick={() => {
                        setOpen(false); setErrors([]); setModalData({
                            nomecontato: '',
                            cargo: '',
                            email: '',
                            whatsapp: ''
                        });
                    }} color='red'>
                        Cancelar  <Icon name='cancel' />
                    </Button>
                    <Button color='green' onClick={handleSave} loading={saveLoading}>
                        Salvar<Icon name='check' />
                    </Button>
                </ModalActions>
            </Modal >

            <Modal
                size='mini'
                open={excluirOpen}
                onClose={() => setExcluirOpen(false)}
                onOpen={() => setExcluirOpen(true)}
            >
                <ModalHeader>Salvar Alteração</ModalHeader>
                <ModalContent>
                    <p>Tem certeza de que deseja excluir este parceiro?</p>
                </ModalContent>
                <ModalActions>
                    <Button negative onClick={() => setExcluirOpen(false)}>
                        Não
                    </Button>
                    <Button positive onClick={handleDelete} loading={deleteLoading}>
                        Sim
                    </Button>
                </ModalActions>
            </Modal>



            <Modal
                size='mini'
                open={salvarOpen}
                onClose={() => setSalvarOpen(false)}
                onOpen={() => setSalvarOpen(true)}
            >
                <ModalHeader>Salvar Parceiro</ModalHeader>
                <ModalContent>
                    <p>Tem certeza de que deseja salvar esta alteração?</p>
                </ModalContent>
                <ModalActions>
                    <Button negative onClick={() => setSalvarOpen(false)}>
                        Não
                    </Button>
                    <Button positive onClick={handleSaveCard} loading={saveCardLoading}>
                        Sim
                    </Button>
                </ModalActions>
            </Modal>

            <Header as='h3' dividing>
                <Icon name='whatsapp' color='green' />
                <HeaderContent>
                    WhatsApp
                    <HeaderSubheader>Cadastre WhatsApp no Parceiro para receber informações</HeaderSubheader>
                </HeaderContent>
            </Header>
            {/* <Input
                name='codparc'
                action={
                    <Button type='submit' onClick={handleSearch} loading={searchLoading}><Icon name={icon} /></Button>
                }
                value={codparc}
                //onChange={(e) => setSearchValue(e.target.value)}
                onChange={(e) => whatsappContatoChangeFieldAction(e)}
                placeholder='Código do Parceiro...'

            /> */}
            <div className="ui input icon loading">
                <DebounceInput
                    name='codparc'
                    minLength={2}
                    debounceTimeout={4000}
                    onChange={(e) => {
                        setOpen(false);
                        whatsappContatoChangeFieldAction(e);
                        handleSearch(e.target.value);

                    }}
                    maxLength={9}
                    placeholder="Código do Parceiro..."
                    value={codparc}
                />
                {searchLoading ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}
            </div>
            <Divider hidden />

            {(status == "OK" || status == "SEMCONTATO") && (  // verifica se contatos existe algum valor, caso exista exibe o botão
                <Button color='green' fluid onClick={() => onOpenPopup()}>
                    <Icon name='plus' />
                    Adicionar Novo Contato
                </Button>
            )}

            {mensagem && (
                <Message info>
                    <Message.Header>Mensagem:</Message.Header>
                    <p>{mensagem}</p>
                </Message>
            )}
            <>
                <Divider hidden />
                <Divider hidden />

                <BrowserView>

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                        <Card.Group>
                            {contatos.map((dados) => (
                                //contato.dados.map((dados) => (

                                < Card fluid key={dados.codcontato} style={{ flex: '1 0 500px', marginBottom: '16px' }}>


                                    <Card.Content>
                                        <Card.Description>
                                            <Label attached='top left'>{dados.codcontato}</Label>
                                            <Form size={'mini'} key={'mini'}>
                                                <Form.Group widths='equal'>
                                                    <Form.Field
                                                        name='nomecontato'
                                                        control='input'
                                                        maxLength={40}
                                                        placeholder='Nome do Contato'
                                                        error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'nomecontato')}
                                                        value={dados.nomecontato}
                                                        onChange={(e) => whatsappContatoChangeAction(e, dados)}
                                                    />
                                                    <Form.Field >
                                                        <Dropdown
                                                            name='cargo'
                                                            defaultValue={dados.cargo}
                                                            options={options}
                                                            placeholder='Cargo'
                                                            selection
                                                            dados={dados}
                                                            onChange={handleCargo}
                                                        />
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field
                                                        name='email'
                                                        control='input'
                                                        placeholder='Email'
                                                        error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'email')}
                                                        maxLength={80}
                                                        value={dados.email}
                                                        onChange={(e) => whatsappContatoChangeAction(e, dados)}
                                                    />
                                                    <PatternFormat icon='hashtag' iconPosition='left'
                                                        name='ad_whatsappcp'
                                                        customInput={Form.Field}
                                                        control='input'
                                                        format="## ##### ####"
                                                        error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'ad_whatsappcp')}
                                                        placeholder='Whatsapp 99 99999 9999'
                                                        value={dados.ad_whatsappcp}
                                                        onChange={(e) => whatsappContatoChangeAction(e, dados)} />
                                                </Form.Group>
                                            </Form>
                                        </Card.Description>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <div className='ui two buttons'>
                                            <Button basic color='green' onClick={() => {
                                                setSelectedSaveCardData({
                                                    nomecontato: dados.nomecontato,
                                                    cargo: dados.cargo,
                                                    email: dados.email,
                                                    whatsapp: dados.ad_whatsappcp,
                                                    codcontato: dados.codcontato
                                                }); validaErro(dados);
                                            }}>
                                                Salvar
                                            </Button>
                                            <Button basic color='red' onClick={() => {
                                                onOpenPopupExcluir(); setSelectedCardData({
                                                    nomecontato: dados.nomecontato,
                                                    cargo: dados.cargo,
                                                    email: dados.email,
                                                    whatsapp: dados.ad_whatsappcp,
                                                    codcontato: dados.codcontato
                                                });
                                            }}>
                                                Excluir
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                                //))
                            ))}
                        </Card.Group>
                    </div>
                </BrowserView>
            </>


            <MobileView>
                <div >
                    <Card.Group>
                        {contatos.map((dados) => (
                            //contato.dados.map((dados) => (
                            < Card fluid key={dados.codcontato}>
                                <Card.Content>
                                    <Card.Description>
                                        <Label attached='top left'>{dados.codcontato}</Label>
                                        <Form size={'mini'} key={'mini'}>
                                            <Form.Group widths='equal'>
                                                <Form.Field
                                                    name='nomecontato'
                                                    control='input'
                                                    maxLength={40}
                                                    placeholder='Nome do Contato'
                                                    error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'nomecontato')}
                                                    value={dados.nomecontato}
                                                    onChange={(e) => whatsappContatoChangeAction(e, dados)}
                                                />
                                                <Form.Field >
                                                    <Dropdown
                                                        name='cargo'
                                                        defaultValue={dados.cargo}
                                                        options={options}
                                                        placeholder='Cargo'
                                                        selection
                                                        dados={dados}
                                                        onChange={handleCargo}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group widths='equal'>
                                                <Form.Field
                                                    name='email'
                                                    control='input'
                                                    placeholder='Email'
                                                    error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'email')}
                                                    maxLength={80}
                                                    value={dados.email}
                                                    onChange={(e) => whatsappContatoChangeAction(e, dados)}
                                                />
                                                <PatternFormat icon='hashtag' iconPosition='left'
                                                    name='ad_whatsappcp'
                                                    customInput={Form.Field}
                                                    control='input'
                                                    format="## ##### ####"
                                                    error={errorSaving.some(a => a.codcontato === dados.codcontato && a.campo == 'ad_whatsappcp')}
                                                    placeholder='Whatsapp 99 99999 9999'
                                                    value={dados.ad_whatsappcp}
                                                    onChange={(e) => whatsappContatoChangeAction(e, dados)} />
                                            </Form.Group>
                                        </Form>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <div className='ui two buttons'>
                                        <Button basic color='green' onClick={() => {
                                            setSelectedSaveCardData({
                                                nomecontato: dados.nomecontato,
                                                cargo: dados.cargo,
                                                email: dados.email,
                                                whatsapp: dados.ad_whatsappcp,
                                                codcontato: dados.codcontato
                                            }); validaErro(dados);
                                        }}>
                                            Salvar
                                        </Button>
                                        <Button basic color='red' onClick={() => {
                                            onOpenPopupExcluir(); setSelectedCardData({
                                                nomecontato: dados.nomecontato,
                                                cargo: dados.cargo,
                                                email: dados.email,
                                                whatsapp: dados.ad_whatsappcp,
                                                codcontato: dados.codcontato
                                            });
                                        }}>
                                            Excluir
                                        </Button>
                                    </div>
                                </Card.Content>
                            </Card>
                            //))
                        ))}
                    </Card.Group>
                </div>
            </MobileView>

        </>

    )

}

const mapStateToProps = (state) => ({
    contatos: state.WhatsappReducer.contatos,
    mensagem: state.WhatsappReducer.mensagem,
    status: state.WhatsappReducer.status,
    codparc: state.WhatsappReducer.codparc
})

export default withRouter(connect(mapStateToProps, {
    whatsappListaContatosAction,
    whatsappCriaAction,
    whatsappContatoChangeAction,
    whatsappContatoAtualizaAction,
    whatsappContatoExcluiAction,
    whatsappContatoChangeFieldAction
}
)(Whatsapp));

