import React, { Component } from 'react';
import { Route } from 'react-router-dom'

import LoginForm from './components/loginform/LoginForm'
import Main from './components/main/Main'


import { Container } from 'semantic-ui-react'




class App extends Component {
  render() {
    return (

      <div>
          <Container style={{ marginTop: '0em' }}>
          <Route exact path="/" component={LoginForm} />
          <Route  path="/Main" component={Main} />
          </Container>
      </div>


    );
  }
}

export default App;
