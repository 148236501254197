import React, { Component } from 'react'
import { Button, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { Form, Input } from 'formsy-semantic-ui-react'

import { Link } from 'react-router-dom';



import {
    loginPostAction
} from '../../actions/LoginActions';


class LoginForm extends Component {


    state = { isLoging: false, Mensagem: [] }


    constructor(props) {
        super(props);

    }


    efetuarLogin = (formData) => {


        this.setState({ isLoging: true });
        this.setState({ Mensagem: [] });
        this.props.loginPostAction(formData).then(result => {
            this.setState({ isLoging: false });
            if (result.status === 'OK') {
                this.props.history.push(`/Main`);
            } else {

                let msg = [];
                msg.push(result.msg)

                this.setState({ Mensagem: msg });
                //this.props.history.push(`/`);
            }


        });



    }


    componentDidMount() {


    }

    render() {




        let { history } = this.props;



        return (


            <div className='login-form'>
                {/*
  Heads up! The styles below are necessary for the correct render of this example.
  You can do same with CSS, the main idea is that all the elements up to the `Grid`
  below must have a height of 100%.
*/}
                <style>{`
  body > div,
  body > div > div,
  body > div > div > div.login-form {
    height: 100%;
  }
`}</style>
                <Grid
                    textAlign='center'
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header as='h2' textAlign='center' style={{ marginTop: '65px' }}>
                            {' '}CRM
                        </Header>
                        {
                            (this.state.Mensagem.length > 0 ? (
                                <Message
                                    error
                                    header='Autenticação'
                                    list={this.state.Mensagem} />
                            ) : (
                                <div></div>
                            ))
                        }


                        <Form size='large' onSubmit={this.efetuarLogin}
                            name="formCRM"
                            ref={ref => this.form = ref}>

                            <Segment stacked>



                                <Form.Input
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder='Login do Mitra'
                                    name='username'
                                    id='username'
                                    maxLength={50}


                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                    name='password'
                                    id='password'
                                    maxLength={10}
                                />

                                <Button loading={this.state.isLoging} basic color='blue' fluid size='large'>Login</Button>
                            </Segment>
                        </Form>
                        {/*<Message>
                        New to us? <a href='#'>Sign Up</a>
                    </Message>*/}
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        isAuthenticated: state.LoginReducer.isAuthenticated
        , codigoConsultor: state.LoginReducer.codigoConsultor
    }
);


//export default LoginForm;


export default withRouter(connect(mapStateToProps, {
    loginPostAction
}
)(LoginForm));
